import styled from 'styled-components';
var pebbleBackground = "/static/merchant/merchant-pebble.png";
export var BaseMerchantCardTitle = styled.h2.withConfig({
  displayName: "style__BaseMerchantCardTitle",
  componentId: "sc-1eknipa-0"
})(["text-align:center;"]);
export var BaseMerchantCardWrapper = styled.div.withConfig({
  displayName: "style__BaseMerchantCardWrapper",
  componentId: "sc-1eknipa-1"
})(["display:flex;flex-direction:column;align-items:center;margin-right:", ";.react-card-flip{display:flex;justify-content:center;align-items:center;width:", ";height:", ";@media ", "{width:", ";height:", ";}.react-card-front{overflow:unset;}.react-card-back{scroll-behavior:smooth;position:fixed;height:100%;background:", ";z-index:", ";}}@media ", "{margin-right:0;}"], function (props) {
  return !props.isPebbleVisible && '20px';
}, function (_ref) {
  var theme = _ref.theme;
  return theme.cardSize.mobileWidth;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.cardSize.mobileHeight;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.responsiveQuery.desktop;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.cardSize.desktopWidth;
}, function (_ref5) {
  var theme = _ref5.theme;
  return theme.cardSize.desktopHeight;
}, function (_ref6) {
  var theme = _ref6.theme;
  return theme.colours.qantasWhite;
}, function (props) {
  return props.isCardFlipped ? '3' : '1';
}, function (_ref7) {
  var theme = _ref7.theme;
  return theme.responsiveQuery.desktop;
});
export var BaseMerchantCardContainer = styled.div.withConfig({
  displayName: "style__BaseMerchantCardContainer",
  componentId: "sc-1eknipa-2"
})(["display:flex;flex-direction:column;width:100%;height:100%;background:url(", ") no-repeat;background-size:55%;background-position:right bottom;background-color:", ";border-radius:6px;box-shadow:rgba(0,0,0,0.2) 0px 2px 4px 0px,rgba(0,0,0,0.05) 0px 8px 8px 0px;@media ", "{border-radius:4px;width:100%;height:", ";}"], pebbleBackground, function (_ref8) {
  var theme = _ref8.theme;
  return theme.colours.qantasWhite;
}, function (_ref9) {
  var theme = _ref9.theme;
  return theme.responsiveQuery.desktop;
}, function (_ref10) {
  var theme = _ref10.theme;
  return theme.cardSize.desktopHeight;
});
export var SplitCardContainer = styled.div.withConfig({
  displayName: "style__SplitCardContainer",
  componentId: "sc-1eknipa-3"
})(["display:flex;flex-direction:row-reverse;justify-content:space-between;align-items:flex-start;min-height:fit-content;@media ", "{flex-direction:row;", "  height:auto;align-items:center;flex:1;}"], function (_ref11) {
  var theme = _ref11.theme;
  return theme.responsiveQuery.desktop;
}, function (props) {
  return !props.isFetchingError && 'padding-bottom: 50px;';
});
export var LeftContainer = styled.div.withConfig({
  displayName: "style__LeftContainer",
  componentId: "sc-1eknipa-4"
})(["display:flex;flex-direction:column;justify-content:center;align-items:center;@media ", "{", "  justify-content:space-evenly;flex:1;}"], function (_ref12) {
  var theme = _ref12.theme;
  return theme.responsiveQuery.desktop;
}, function (props) {
  return !!props.isFetchingError && 'padding-bottom: 53px;';
});
export var RightContainer = styled.div.withConfig({
  displayName: "style__RightContainer",
  componentId: "sc-1eknipa-5"
})(["display:flex;flex-direction:column;align-items:flex-start;width:100%;@media ", "{", "   justify-content:space-evenly;}"], function (_ref13) {
  var theme = _ref13.theme;
  return theme.responsiveQuery.desktop;
}, function (props) {
  return !!props.isFetchingError && 'padding-top: 39px;';
});
export var MobileDisplayOnly = styled.div.withConfig({
  displayName: "style__MobileDisplayOnly",
  componentId: "sc-1eknipa-6"
})(["display:block;height:100%;@media ", "{display:none;height:auto;}"], function (_ref14) {
  var theme = _ref14.theme;
  return theme.responsiveQuery.desktop;
});
export var DesktopDisplayOnly = styled.div.withConfig({
  displayName: "style__DesktopDisplayOnly",
  componentId: "sc-1eknipa-7"
})(["display:none;@media ", "{display:block;}"], function (_ref15) {
  var theme = _ref15.theme;
  return theme.responsiveQuery.desktop;
});
export var CardFooterCaptionContainer = styled.div.withConfig({
  displayName: "style__CardFooterCaptionContainer",
  componentId: "sc-1eknipa-8"
})(["padding:0 15px;max-width:305px;@media ", "{padding:0;max-width:100%;}"], function (_ref16) {
  var theme = _ref16.theme;
  return theme.responsiveQuery.desktop;
});