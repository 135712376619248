import _toConsumableArray from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import React from "react";
var __jsx = React.createElement;
import styled from 'styled-components';
import { Container, Tabs, Accordion, ArrowLink } from "../_global";
import { fontSizes } from "../../constants/css";
export var FAQContainer = styled.div.withConfig({
  displayName: "FAQs__FAQContainer",
  componentId: "sc-11jtbr3-0"
})(["margin:3rem 0;h2{margin:0 0 1.375rem;font-size:", ";}"], fontSizes.large);
var FAQFooter = styled.div.withConfig({
  displayName: "FAQs__FAQFooter",
  componentId: "sc-11jtbr3-1"
})(["margin-top:1.625rem;text-align:center;h2{font-weight:normal;margin-bottom:0.5rem;font-size:1.5rem;}p{margin:0 0 1rem;}"]);
var FAQs = function FAQs(_ref) {
  var faqSections = _ref.faqSections;
  var sortedFaqSections = _toConsumableArray(faqSections).sort(function (a, b) {
    return a.id - b.id;
  });
  return __jsx(FAQContainer, null, __jsx(Container, {
    wideView: true,
    mobilePadding: "0"
  }, __jsx(Tabs, null, sortedFaqSections.map(function (_ref2, i) {
    var title = _ref2.title,
      faqs = _ref2.faqs;
    return __jsx("div", {
      key: i,
      label: title
    }, __jsx("h2", null, "Need help? Common questions related to purchases"), __jsx(Accordion, {
      items: faqs
    }));
  }))), __jsx(FAQFooter, null, __jsx("h2", null, "Can't find what you're looking for?"), __jsx("p", null, "Want more info?\xA0", __jsx(ArrowLink, {
    text: "Ask a question",
    href: "/contact-us"
  }))));
};
export default FAQs;