import _defineProperty from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
var __jsx = React.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import React, { useState, useEffect } from 'react';
import Router from 'next/router';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import { colours } from "../../../../../constants/css";
import BaseMerchantCard from "../BaseMerchantCard";
import DirectLeftSideCard from "./DirectLeftSideCard";
import DirectRightSideCard from "./DirectRightSideCard";
import DirectBottomSideCard from "./DirectBottomSideCard";
import DirectBackSideCard from "./DirectBackSideCard";
import CardFooterCaption from "../BaseMerchantCard/CardFooterCaption";
import { getRedemption } from "./getRedemption";
import CardPebble from "../CardPebble";
import { MerchantCardWrapper } from "../../../Style/index";
var initialState = {
  isFlipped: false,
  isExclusions: false,
  isPebbleVisible: false
};
export var DirectMerchantCard = function DirectMerchantCard(_ref) {
  var slug = _ref.slug,
    tile = _ref.tile;
  var _useState = useState(initialState),
    state = _useState[0],
    setState = _useState[1];
  var redemption = getRedemption(tile.redemptionType);
  useEffect(function () {
    setState({
      isPebbleVisible: tile.showPebble !== null
    });
  }, [tile.showPebble]);
  var handleCardFlip = function handleCardFlip() {
    setState(function (prevState) {
      return {
        isFlipped: !prevState.isFlipped,
        isPebbleVisible: false
      };
    });
  };
  var handleExclusionsClicked = function handleExclusionsClicked() {
    setState(function (prevState) {
      return {
        isExclusions: true,
        isFlipped: !prevState.isFlipped,
        isPebbleVisible: false
      };
    });
  };
  var handleCardButton = function handleCardButton(url) {
    if (!url) {
      return;
    }
    if (url[0] !== '/') {
      window.open(url);
    } else {
      Router.push(url);
    }
  };
  var onCloseBackCard = function onCloseBackCard() {
    setState({
      isFlipped: false,
      isPebbleVisible: tile.showPebble !== null
    });
  };
  return __jsx(MerchantCardWrapper, null, __jsx(BaseMerchantCard, {
    isPebbleVisible: state.isPebbleVisible,
    className: "direct-merchant-card",
    slug: slug,
    leftSide: __jsx(DirectLeftSideCard, {
      content: redemption
    }),
    rightSide: __jsx(DirectRightSideCard, {
      tile: tile
    }),
    backSideCard: state.isFlipped && __jsx(DirectBackSideCard, {
      tile: tile,
      isExclusions: state.isExclusions,
      onCloseBackCard: onCloseBackCard
    }),
    footerCardCaption: tile.creditedInDays ? __jsx(CardFooterCaption, {
      days: tile.creditedInDays
    }) : null,
    isCardFlipped: state.isFlipped
  }, __jsx(DirectBottomSideCard, {
    tile: _objectSpread({
      celebrusName: "merchant_direct_".concat(slug)
    }, tile),
    handleCardButton: handleCardButton,
    handleCardFlip: handleCardFlip,
    handleExclusionsClicked: handleExclusionsClicked
  })), __jsx(CardPebble, {
    orColor: colours.qantasLightOrange,
    isPebbleVisible: state.isPebbleVisible,
    showPebble: tile.showPebble
  }));
};
var mapStateToProps = function mapStateToProps(_ref2) {
  var user = _ref2.user;
  return {
    user: user
  };
};
export default compose(connect(mapStateToProps, null))(DirectMerchantCard);