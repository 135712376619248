import _toConsumableArray from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
var _icons;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { REDEMPTION_TYPES } from "../../../../../../constants/app";
var onlineIcon = "/static/merchant/online.png";
var instoreIcon = "/static/merchant/instore.png";
var mastercardIcon = "/static/icons/mastercardLogo.svg";
var masterCardVisa = "/static/offers/mastercard-visa-vertical@2x.png";
var masterCardVisaAmex = "/static/offers/mastercard-visa-amex-vertical@2x.png";
var icons = (_icons = {}, _defineProperty(_icons, REDEMPTION_TYPES.ONLINE, {
  iconLabel: 'ONLINE',
  iconList: [{
    src: onlineIcon,
    alt: 'Online'
  }],
  hasStore: false
}), _defineProperty(_icons, REDEMPTION_TYPES.INSTORE, {
  iconLabel: 'IN-STORE',
  iconList: [{
    src: instoreIcon,
    alt: 'In store'
  }],
  hasStore: true
}), _defineProperty(_icons, REDEMPTION_TYPES.BOTH, {
  iconLabel: 'ONLINE & IN-STORE',
  iconList: [{
    src: onlineIcon,
    alt: 'Online'
  }, {
    src: instoreIcon,
    alt: 'In store'
  }],
  hasStore: true
}), _icons);
export var getRedemption = function getRedemption(redemptionType, _ref) {
  var isVisaAmexEnabled = _ref.isVisaAmexEnabled;
  var cardIcon = isVisaAmexEnabled ? {
    src: masterCardVisaAmex,
    alt: 'Cards Icon',
    type: 'stackedLogos'
  } : {
    src: masterCardVisa,
    alt: 'Mastercard and Visa Icon',
    type: 'stackedLogos'
  };
  var redemptions = icons[redemptionType] || {
    iconLabel: null,
    iconList: [],
    hasStore: false
  };
  return _objectSpread(_objectSpread({}, redemptions), {}, {
    iconList: [].concat(_toConsumableArray(redemptions.iconList), [cardIcon])
  });
};