import styled from 'styled-components';
import { Container, Button } from "../../_global";
import { layoutSizes, fontSizes, colours, customFonts } from "../../../constants/css";
export var ContainerStyled = styled(Container).withConfig({
  displayName: "style__ContainerStyled",
  componentId: "sc-16ohq6v-0"
})(["display:flex;flex-direction:column;align-items:center;"]);
export var WWPartnerLogos = styled.img.withConfig({
  displayName: "style__WWPartnerLogos",
  componentId: "sc-16ohq6v-1"
})(["width:300px;padding-bottom:50px;@media only screen and (min-width:", "){width:185px;}"], layoutSizes.desktopWidth);
export var ChildrenContainer = styled.div.withConfig({
  displayName: "style__ChildrenContainer",
  componentId: "sc-16ohq6v-2"
})(["margin-top:1em;padding-bottom:2em;"]);
export var HeadingImgContainer = styled.div.withConfig({
  displayName: "style__HeadingImgContainer",
  componentId: "sc-16ohq6v-3"
})(["display:flex;justify-content:center;margin-top:", ";max-height:80px;@media only screen and (min-width:", "){max-height:100px;margin-top:90px;}"], function (props) {
  return props.hasBannerAbove ? '70px' : '120px';
}, layoutSizes.desktopWidth);
export var MerchantLogoContainer = styled.div.withConfig({
  displayName: "style__MerchantLogoContainer",
  componentId: "sc-16ohq6v-4"
})(["text-align:center;display:flex;flex-direction:column;@media only screen and (min-width:", "){white-space:nowrap;flex-direction:row;}width:100%;"], layoutSizes.desktopWidth);
export var StatusIconContainer = styled.div.withConfig({
  displayName: "style__StatusIconContainer",
  componentId: "sc-16ohq6v-5"
})(["text-align:center;width:100%;margin-top:1em;"]);
export var StatusIcon = styled.img.withConfig({
  displayName: "style__StatusIcon",
  componentId: "sc-16ohq6v-6"
})(["justify-content:center;"]);
export var ButtonStyled = styled(Button).withConfig({
  displayName: "style__ButtonStyled",
  componentId: "sc-16ohq6v-7"
})(["width:230px;outline:none;a{text-decoration:none;color:", ";}"], colours.qantasWhite);
export var WWMerchant = styled.div.withConfig({
  displayName: "style__WWMerchant",
  componentId: "sc-16ohq6v-8"
})(["align-items:center;@media only screen and (min-width:", "){display:flex;margin-left:0.5em;margin-right:0.5em;flex-direction:column;}"], layoutSizes.desktopWidth);
export var MerchantLogos = styled.img.withConfig({
  displayName: "style__MerchantLogos",
  componentId: "sc-16ohq6v-9"
})(["width:200px;height:150px;@media only screen and (min-width:", "){width:300px;}padding:1em 3em 1em 3em;justify-content:center;"], layoutSizes.desktopWidth);
export var HeadingImg = styled.img.withConfig({
  displayName: "style__HeadingImg",
  componentId: "sc-16ohq6v-10"
})(["width:250px;height:120px;transform:translate(0,-50px);@media only screen and (min-width:", "){width:350px;height:166px;transform:translate(0,-70px);}"], layoutSizes.desktopWidth);
export var HeaderContainer = styled.div.withConfig({
  displayName: "style__HeaderContainer",
  componentId: "sc-16ohq6v-11"
})(["display:flex;justify-content:center;align-items:center;width:100%;padding:6px 0 12px 0;@media only screen and (min-width:", "){width:802px;}> h2{margin-bottom:0;}"], layoutSizes.desktopWidth);
export var H1Styled = styled.h1.withConfig({
  displayName: "style__H1Styled",
  componentId: "sc-16ohq6v-12"
})(["font-size:2.125rem;text-align:center;font-weight:normal;line-height:1.22;letter-spacing:normal;color:", ";margin:0;font-family:", ";@media only screen and (min-width:", "){font-size:2.875rem;}"], colours.qantasCharcoal, customFonts.ciutadellaReg, layoutSizes.desktopWidth);
export var H2Styled = styled.h2.withConfig({
  displayName: "style__H2Styled",
  componentId: "sc-16ohq6v-13"
})(["font-size:1.625rem;text-align:center;line-height:1.38;letter-spacing:normal;color:", ";font-family:", ";font-weight:500;margin-top:40px;@media only screen and (min-width:", "){margin-top:12px;}"], colours.qantasCharcoal, customFonts.ciutadellaMed, layoutSizes.desktopWidth);
export var PStyled = styled.p.withConfig({
  displayName: "style__PStyled",
  componentId: "sc-16ohq6v-14"
})(["font-size:", ";line-height:1.56;text-align:", ";color:", ";margin:0;max-width:346px;@media only screen and (min-width:", "){text-align:", ";min-width:800px;max-width:800px;", "}strong{padding-left:15px;padding-right:15px;@media only screen and (min-width:", "){padding-left:30px;padding-right:30px;}}"], fontSizes.large, function (_ref) {
  var mobileAlign = _ref.mobileAlign;
  return mobileAlign && mobileAlign === 'center' ? 'center' : mobileAlign;
}, colours.qantasCharcoal, layoutSizes.desktopWidth, function (_ref2) {
  var align = _ref2.align;
  return align && align === 'center' ? 'center' : align;
}, function (_ref3) {
  var whereToEarn = _ref3.whereToEarn;
  return whereToEarn && "\n        min-width: 100%;\n      ";
}, layoutSizes.desktopWidth);
export var HowToSection = styled.section.withConfig({
  displayName: "style__HowToSection",
  componentId: "sc-16ohq6v-15"
})(["display:flex;flex-direction:column;align-items:center;background:", ";width:100%;padding-bottom:40px;@media only screen and (min-width:", "){padding-bottom:30px;}"], colours.qantasIceWhite, layoutSizes.desktopWidth);
export var WhereToEarnSection = styled(HowToSection).withConfig({
  displayName: "style__WhereToEarnSection",
  componentId: "sc-16ohq6v-16"
})(["display:flex;flex-direction:column;align-items:center;justify-content:center;width:100%;padding-bottom:0;@media only screen and (min-width:", "){padding-bottom:0;flex-direction:row;background:", ";}"], layoutSizes.desktopWidth, colours.qantasGray91);
export var TermSection = styled.section.withConfig({
  displayName: "style__TermSection",
  componentId: "sc-16ohq6v-17"
})(["background-color:", ";color:", ";padding-top:48px;"], colours.qantasIceWhite, colours.qantasTermsGrey);
export var RewardsLoadingSection = styled.section.withConfig({
  displayName: "style__RewardsLoadingSection",
  componentId: "sc-16ohq6v-18"
})(["min-height:372px;padding-bottom:0;justify-content:center;display:", ";flex-direction:column;align-items:center;background:", ";width:100%;@media only screen and (min-width:", "){padding-bottom:64px;}"], function (props) {
  return props.show ? 'flex' : 'none';
}, colours.qantasIceWhite, layoutSizes.desktopWidth);