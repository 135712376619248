var __jsx = React.createElement;
import React from 'react';
import Markdown from 'react-markdown';
import { MerchantAboutTitle, MerchantDescription, AboutWrapper, AboutImage } from "../Style/index";
import { merchantProps } from "../Detail/merchantProps";
var About = function About(props) {
  var merchant = props.merchant,
    children = props.children;
  return __jsx(AboutWrapper, null, merchant.aboutImage && __jsx(AboutImage, {
    src: merchant.aboutImage
  }), __jsx(MerchantDescription, null, __jsx(MerchantAboutTitle, null, "About ", merchant.merchantName), __jsx(Markdown, null, merchant.merchantDescriptionLong)), children);
};
export default About;