import { OFFER_TYPES } from "../../../../../../constants/app";
import { isExpired } from "../../../../../Offer/offerUtils";
import _ from 'lodash';

/**
 * getMcoOffer - Retrieve the current merchant's offer from a list of mco offers
 * @param mcoOffers
 * @param merchant
 * @return { mcoOfferedData | null }
 */
export var getMcoOffer = function getMcoOffer(_ref) {
  var mcoOffers = _ref.mcoOffers,
    merchant = _ref.merchant;
  if (!(mcoOffers && mcoOffers.length > 0 && merchant)) {
    return null;
  }
  var merchantOffers = mcoOffers.filter(function (offer) {
    var isMerchantOffer = offer.merchantId === merchant.merchantId;
    var isActivatedOfferExpired = isExpired(OFFER_TYPES.MCO, offer.eventEndDate) && offer.activated;
    var isOfferExpired = isExpired(OFFER_TYPES.MCO, offer.eventEndDate);
    return isMerchantOffer && (!isOfferExpired || isActivatedOfferExpired);
  });
  return _.first(merchantOffers) || null;
};