var __jsx = React.createElement;
import React from 'react';
import { Button } from "../../../_global";
import { ChooseContainer, HowToButtonStyled, JoinWw, Arrow, Space } from "./style";
var ChooseQantas = function ChooseQantas(_ref) {
  var content = _ref.content,
    clickAction = _ref.clickAction,
    clickAction2 = _ref.clickAction2;
  var _ref2 = content || {},
    buttonLabel = _ref2.buttonLabel;
  return __jsx(ChooseContainer, null, buttonLabel && __jsx(HowToButtonStyled, {
    primary: true,
    onPress: function onPress() {
      return clickAction();
    }
  }, buttonLabel), clickAction2 && __jsx(JoinWw, null, "Not an Everyday Rewards member?", __jsx(Button, {
    link: true,
    onPress: function onPress() {
      return clickAction2();
    }
  }, "Join now ", __jsx(Arrow, null))), !clickAction2 && __jsx(Space, null));
};
ChooseQantas.defaultProps = {
  clickAction2: null
};
export default ChooseQantas;