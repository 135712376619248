import PropTypes from 'prop-types';
var defaultTileProps = {
  slug: PropTypes.string.isRequired,
  merchant: PropTypes.shape().isRequired,
  // eslint-disable-line
  user: PropTypes.shape({
    canDisplayTile: PropTypes.bool,
    isLinkedMcard: PropTypes.bool
  }),
  renderLoginModal: PropTypes.func.isRequired,
  // eslint-disable-line
  mcoOffers: PropTypes.arrayOf(PropTypes.object),
  // eslint-disable-line
  mcoOffer: PropTypes.shape({
    activated: PropTypes.bool
  }).isRequired,
  // eslint-disable-line
  content: PropTypes.shape({})
};
export { defaultTileProps };