import styled from 'styled-components';
import Button from "../../../_global/Button";
import { layoutSizes, lineHeights, fontSizes, colours } from "../../../../constants/css";
import { H2Styled as H2Styled_, PStyled as PStyled_ } from "../style";
export var WhereToEarnContainer = styled.div.withConfig({
  displayName: "style__WhereToEarnContainer",
  componentId: "sc-1lt1rqb-0"
})(["display:flex;flex-direction:column;align-items:flex-start;width:100%;@media only screen and (min-width:", "){flex-direction:row;align-items:stretch;}"], layoutSizes.desktopWidth);
export var WhereToEarnImageContainer = styled.div.withConfig({
  displayName: "style__WhereToEarnImageContainer",
  componentId: "sc-1lt1rqb-1"
})(["position:relative;width:100%;min-height:187px;@media only screen and (min-width:", "){width:50%;min-height:358px;}"], layoutSizes.desktopWidth);
export var WhereToEarnCopyContainer = styled.div.withConfig({
  displayName: "style__WhereToEarnCopyContainer",
  componentId: "sc-1lt1rqb-2"
})(["display:flex;flex-direction:column;align-items:flex-start;width:100%;padding:1.5rem;background:", ";@media only screen and (min-width:", "){width:50%;min-width:496px;padding:2.5rem;}"], colours.qantasMediumGrey, layoutSizes.desktopWidth);
export var H2Styled = styled(H2Styled_).withConfig({
  displayName: "style__H2Styled",
  componentId: "sc-1lt1rqb-3"
})(["margin:0 0 0.5rem 0;text-align:left;font-size:", ";line-height:", ";"], fontSizes.xl, lineHeights.lh30);
export var PStyled = styled(PStyled_).withConfig({
  displayName: "style__PStyled",
  componentId: "sc-1lt1rqb-4"
})(["max-width:none;font-size:", ";line-height:", ";"], fontSizes.base, lineHeights.lh24);
export var WhereToEarnCopy = styled.div.withConfig({
  displayName: "style__WhereToEarnCopy",
  componentId: "sc-1lt1rqb-5"
})(["margin-bottom:1.5rem;max-width:750px;"]);
export var PartnerLogosContainer = styled.div.withConfig({
  displayName: "style__PartnerLogosContainer",
  componentId: "sc-1lt1rqb-6"
})(["display:flex;height:48px;margin-bottom:1.5rem;@media only screen and (min-width:", "){height:56px;}"], layoutSizes.desktopWidth);
export var PartnerLogoImg = styled.img.withConfig({
  displayName: "style__PartnerLogoImg",
  componentId: "sc-1lt1rqb-7"
})(["display:inline-block;height:100%;width:auto;:not(:last-child){margin-right:0.5rem;}@media only screen and (min-width:", "){:not(:last-child){margin-right:1rem;}}"], layoutSizes.desktopWidth);
export var FindOutMoreContainer = styled.div.withConfig({
  displayName: "style__FindOutMoreContainer",
  componentId: "sc-1lt1rqb-8"
})([""]);
export var ButtonStyled = styled(Button).withConfig({
  displayName: "style__ButtonStyled",
  componentId: "sc-1lt1rqb-9"
})(["padding:12px 28px;"]);