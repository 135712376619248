import _objectWithoutProperties from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["orColor", "plusColor", "isPebbleVisible", "showPebble"];
var __jsx = React.createElement;
import React from 'react';
import { OrPebble, PlusPebble } from "../../../_global/Icons";
import { SUPPORTED_SCREENS, useBreakpoint } from "../../../../hooks/useBreakpoint";
import { PebbleContainer } from "../../Style";
var CardPebble = function CardPebble(_ref) {
  var _ref$orColor = _ref.orColor,
    orColor = _ref$orColor === void 0 ? undefined : _ref$orColor,
    _ref$plusColor = _ref.plusColor,
    plusColor = _ref$plusColor === void 0 ? undefined : _ref$plusColor,
    _ref$isPebbleVisible = _ref.isPebbleVisible,
    isPebbleVisible = _ref$isPebbleVisible === void 0 ? true : _ref$isPebbleVisible,
    _ref$showPebble = _ref.showPebble,
    showPebble = _ref$showPebble === void 0 ? 'OR' : _ref$showPebble,
    rest = _objectWithoutProperties(_ref, _excluded);
  if (!isPebbleVisible) {
    return false;
  }
  var _useBreakpoint = useBreakpoint(),
    breakpoint = _useBreakpoint.breakpoint;
  var isMobile = breakpoint === SUPPORTED_SCREENS.MOBILE;
  var color = showPebble === 'OR' ? orColor : plusColor;
  var pebbleProps = isMobile ? {
    width: '54px',
    height: '52px',
    color: color
  } : {
    color: color
  };
  return __jsx(PebbleContainer, rest, showPebble === 'OR' ? __jsx(OrPebble, pebbleProps) : __jsx(PlusPebble, pebbleProps));
};
CardPebble.defaultProps = {
  isPebbleVisible: false,
  showPebble: null,
  orColor: undefined,
  plusColor: undefined
};
export default CardPebble;