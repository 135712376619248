var __jsx = React.createElement;
import React from 'react';
import Image from 'next/image';
var whereToEarnBanner = "/static/plugin/where-to-earn-hiker.jpg";
var whereToEarnLogo1 = "/static/icons/where-to-earn/Woolworths.png";
var whereToEarnLogo2 = "/static/icons/where-to-earn/Metro.png";
var whereToEarnLogo3 = "/static/icons/where-to-earn/Milkrun.png";
var whereToEarnLogo4 = "/static/icons/where-to-earn/Bigw.png";
var whereToEarnLogo5 = "/static/icons/where-to-earn/BWS.png";
var whereToEarnLogo6 = "/static/icons/where-to-earn/Healthylife.png";
import { WhereToEarnContainer, WhereToEarnImageContainer, WhereToEarnCopyContainer, WhereToEarnCopy, PartnerLogosContainer, PartnerLogoImg, FindOutMoreContainer, H2Styled, PStyled, ButtonStyled } from "./style";
function WhereToEarn() {
  var findOutMoreLink = 'https://www.everyday.com.au/rewards/partners.html';
  return __jsx(WhereToEarnContainer, null, __jsx(WhereToEarnImageContainer, null, __jsx(Image, {
    src: whereToEarnBanner,
    alt: "woolworths hiking girl",
    layout: "fill",
    objectFit: "cover"
  })), __jsx(WhereToEarnCopyContainer, null, __jsx(H2Styled, null, "Where can I shop to collect Everyday Rewards points?"), __jsx(WhereToEarnCopy, null, __jsx(PStyled, {
    align: "left",
    mobileAlign: "left",
    whereToEarn: true
  }, "Shop at Woolworths, Woolworths Metro, BIG W, BWS, MILKRUN, Healthylife, and more. Simply scan your Everyday Rewards card in-store or link it when shopping online to collect points.")), __jsx(PartnerLogosContainer, null, __jsx(PartnerLogoImg, {
    src: whereToEarnLogo1,
    alt: "ww-partner-logo-1"
  }), __jsx(PartnerLogoImg, {
    src: whereToEarnLogo2,
    alt: "ww-partner-logo-2"
  }), __jsx(PartnerLogoImg, {
    src: whereToEarnLogo3,
    alt: "ww-partner-logo-3"
  }), __jsx(PartnerLogoImg, {
    src: whereToEarnLogo4,
    alt: "ww-partner-logo-4"
  }), __jsx(PartnerLogoImg, {
    src: whereToEarnLogo5,
    alt: "ww-partner-logo-5"
  }), __jsx(PartnerLogoImg, {
    src: whereToEarnLogo6,
    alt: "ww-partner-logo-6"
  })), __jsx(FindOutMoreContainer, null, __jsx(ButtonStyled, {
    primary: true,
    pageLink: findOutMoreLink,
    asLink: findOutMoreLink
  }, "Find Out More"))));
}
export default WhereToEarn;