import styled from 'styled-components';
import { layoutSizes, colours, fontSizes, customFonts } from "../../../../constants/css";
import { Button } from "../../../_global";
var arrow = "/static/link-arrow.svg";
import { H2Styled, PStyled } from "../style";
export var WWPartnerLogos = styled.img.withConfig({
  displayName: "style__WWPartnerLogos",
  componentId: "sc-btvd5k-0"
})(["width:225px;@media only screen and (min-width:", "){width:185px;}"], layoutSizes.desktopWidth);
export var HowToContainer = styled.div.withConfig({
  displayName: "style__HowToContainer",
  componentId: "sc-btvd5k-1"
})(["width:100%;display:flex;flex-direction:column;justify-content:center;align-items:center;padding:0 15px;@media only screen and (min-width:", "){max-width:800px;}"], layoutSizes.desktopWidth);
export var Heading = styled(H2Styled).withConfig({
  displayName: "style__Heading",
  componentId: "sc-btvd5k-2"
})(["max-width:346px;@media only screen and (min-width :", "){max-width:100%;}"], layoutSizes.desktopWidth);
export var Icon = styled.img.withConfig({
  displayName: "style__Icon",
  componentId: "sc-btvd5k-3"
})(["width:48px;height:48px;margin-top:15px;margin-bottom:15px;@media only screen and (min-width:", "){margin-top:20px;margin-bottom:20px;}"], layoutSizes.desktopWidth);
export var HowToButtonStyled = styled(Button).withConfig({
  displayName: "style__HowToButtonStyled",
  componentId: "sc-btvd5k-4"
})(["min-width:320px;a{text-decoration:none;color:", ";}@media only screen and (min-width:", "){min-width:290px;}"], colours.qantasWhite, layoutSizes.desktopWidth);
export var JoinWw = styled.div.withConfig({
  displayName: "style__JoinWw",
  componentId: "sc-btvd5k-5"
})(["display:flex;flex-direction:column;align-items:center;font-size:", ";padding-top:15px;button{text-transform:none;padding-left:0.5rem;color:", ";font-size:", ";letter-spacing:0;font-family:", ";}@media only screen and (min-width:", "){flex-direction:row;padding-top:24px;}"], fontSizes.large, colours.qantasRed, fontSizes.large, customFonts.ciutadellaReg, layoutSizes.desktopWidth);
export var SuccessIcon = styled(Icon).withConfig({
  displayName: "style__SuccessIcon",
  componentId: "sc-btvd5k-6"
})(["margin:48px 0 0 0;@media only screen and (min-width:", "){margin-top:32px;}"], layoutSizes.desktopWidth);
export var SuccessHeading = styled(H2Styled).withConfig({
  displayName: "style__SuccessHeading",
  componentId: "sc-btvd5k-7"
})(["margin-top:24px;@media only screen and (min-width:", "){margin-top:32px;margin-bottom:0;}"], layoutSizes.desktopWidth);
export var SuccessCopy = styled(PStyled).withConfig({
  displayName: "style__SuccessCopy",
  componentId: "sc-btvd5k-8"
})(["margin:16px 0 24px 0;@media only screen and (min-width:", "){margin:18px 0 22px 0;}"], layoutSizes.desktopWidth);
export var Arrow = styled.i.withConfig({
  displayName: "style__Arrow",
  componentId: "sc-btvd5k-9"
})(["display:inline-block;background:url(", ");background-repeat:no-repeat;height:7px;width:11px;"], arrow);