var __jsx = React.createElement;
import React from 'react';
var excludeIcon = "/static/icons/ico_exclude.svg";
import _ from 'lodash';
import CardSubHeading from "../../BaseMerchantCard/CardSubHeading";
import CardTermsButton from "../../BaseMerchantCard/CardTermsButton";
import { MobileDisplayOnly } from "../../BaseMerchantCard/style";
import { CardSubHeadingContainer } from "../style";
import { CardFooterContainer, CardButton, CardTermsButtonContainer } from "./style";
var DirectBottomSideCard = function DirectBottomSideCard(_ref) {
  var tile = _ref.tile,
    handleCardButton = _ref.handleCardButton,
    handleCardFlip = _ref.handleCardFlip,
    handleExclusionsClicked = _ref.handleExclusionsClicked;
  var callToAction = _.get(tile, 'callToActions[0]');
  return __jsx(CardFooterContainer, null, __jsx(MobileDisplayOnly, null, __jsx(CardSubHeadingContainer, null, __jsx(CardSubHeading, {
    copy: tile.subtitle
  }))), callToAction && __jsx(CardButton, {
    primary: callToAction.primary,
    celebrusName: tile.celebrusName,
    block: true,
    onPress: function onPress() {
      return handleCardButton(callToAction.link);
    }
  }, callToAction.name), __jsx(CardTermsButtonContainer, {
    isExclusions: tile.exclusions,
    callToAction: callToAction
  }, tile.exclusions && __jsx(CardTermsButton, {
    buttonLabel: "Exclusions",
    textColor: "#f4750c",
    onClick: handleExclusionsClicked,
    icon: excludeIcon
  }), tile.terms && __jsx(CardTermsButton, {
    primary: true,
    buttonLabel: "Terms & Conditions",
    onClick: handleCardFlip
  })));
};
DirectBottomSideCard.displayName = 'DirectBottomSideCard';
export default DirectBottomSideCard;