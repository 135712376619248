import _defineProperty from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var getFilteredFaqs = function getFilteredFaqs(merchant, faqSections) {
  return merchant.merchantFaq && merchant.merchantFaq.length > 0 ? merchant.merchantFaq : faqSections && faqSections.filter(function (faq) {
    return merchant.merchantIdOLM && faq.id === 1 || merchant.merchantIdMCO && faq.id === 2 || merchant.merchantIdDirect && faq.id === 3;
  }).map(function (faq) {
    if (faq.id === 3) {
      return _objectSpread(_objectSpread({}, faq), {}, {
        title: merchant.merchantName
      });
    }
    return faq;
  });
};
export default getFilteredFaqs;