import _extends from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/extends";
var __jsx = React.createElement;
import React from 'react';
import { CardIconsContainer, CardIconLabel, CardIconImg } from "./style";
var CardIcon = function CardIcon(props) {
  var iconLabel = props.iconLabel,
    iconList = props.iconList;
  return __jsx(React.Fragment, null, iconLabel && __jsx(CardIconLabel, null, iconLabel), __jsx(CardIconsContainer, props, iconList && iconList.map(function (imgProps, index) {
    return __jsx(CardIconImg, _extends({
      key: "".concat(index, "-").concat(imgProps.alt)
    }, imgProps));
  })));
};
export default CardIcon;