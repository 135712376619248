import styled from 'styled-components';
import { Button } from "../../../../../_global";
export var CardTermsButtonLabelContainer = styled.div.withConfig({
  displayName: "style__CardTermsButtonLabelContainer",
  componentId: "sc-1jqc5s3-0"
})(["display:flex;align-items:center;"]);
export var CardTermsButtonLabel = styled(Button).withConfig({
  displayName: "style__CardTermsButtonLabel",
  componentId: "sc-1jqc5s3-1"
})(["display:", ";margin:0;padding:0;text-transform:capitalize;text-decoration:underline;", " background:none;font-family:", ";font-size:", ";line-height:2.85;letter-spacing:0.93px;outline:transparent;@media ", "{font-size:", ";line-height:normal;letter-spacing:normal;margin:0;}"], function (props) {
  return props.showTermsInfo ? 'none' : 'block';
}, function (_ref) {
  var color = _ref.color;
  return color && "color: ".concat(color, ";");
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.customFonts.ciutadellaReg;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.fontSizes.small;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.responsiveQuery.desktop;
}, function (_ref5) {
  var theme = _ref5.theme;
  return theme.fontSizes.base;
});
export var CardTermsButtonLabelIcon = styled.img.withConfig({
  displayName: "style__CardTermsButtonLabelIcon",
  componentId: "sc-1jqc5s3-2"
})(["width:20px;height:15px;"]);