var __jsx = React.createElement;
import React, { useState } from 'react';
import Router from 'next/router';
import BaseMerchantCard from "../../BaseMerchantCard";
import McoTopSideCard from "../McoTopSideCard";
import McoLeftSideCard from "../McoLeftSideCard";
import McoRightSideCard from "../McoRightSideCard";
import McoBackSideCard from "../McoBackSideCard";
import CardFooterCaption from "../../BaseMerchantCard/CardFooterCaption";
import McoBottomSideCard from "../McoBottomSideCard";
import { defaultTileProps } from "../utils/props";
var McoOfferActiveTile = function McoOfferActiveTile(props) {
  var _useState = useState({
      isFlipped: props.isFlipped || false
    }),
    state = _useState[0],
    setState = _useState[1];
  var slug = props.slug,
    mcoOffer = props.mcoOffer,
    content = props.content;
  var handleCardFlip = function handleCardFlip() {
    setState(function (prevState) {
      return {
        isFlipped: !prevState.isFlipped
      };
    });
  };
  var onCloseBackCard = function onCloseBackCard() {
    setState({
      isFlipped: false
    });
  };
  var handleCardButton = function handleCardButton(url) {
    if (!url) {
      return;
    }
    if (url[0] !== '/') {
      window.open(url);
    } else {
      Router.push(url);
    }
  };
  return __jsx(BaseMerchantCard, {
    className: "mco-merchant-card",
    slug: slug,
    topSide: __jsx(McoTopSideCard, {
      content: content
    }),
    leftSide: __jsx(McoLeftSideCard, {
      content: content
    }),
    rightSide: __jsx(McoRightSideCard, {
      content: content
    }),
    backSideCard: state.isFlipped && __jsx(McoBackSideCard, {
      content: content,
      onCloseBackCard: onCloseBackCard
    }),
    isCardFlipped: state.isFlipped,
    footerCardCaption: __jsx(CardFooterCaption, {
      days: 15
    })
  }, mcoOffer.activated && __jsx(McoBottomSideCard, {
    content: content,
    handleCardButton: handleCardButton,
    handleCardFlip: handleCardFlip
  }));
};
McoOfferActiveTile.displayName = 'McoOfferActiveTile';
export default McoOfferActiveTile;