var __jsx = React.createElement;
import React from 'react';
import { CardHeadingcontainer, H2Styled, PointsStyled, WasPointsStyled } from "./style";
var CardHeading = function CardHeading(_ref) {
  var actualPoints = _ref.actualPoints,
    prevPoints = _ref.prevPoints,
    perValue = _ref.perValue,
    copy = _ref.copy;
  var formatPoints = function formatPoints(number) {
    return Number(number).toLocaleString('en-AU');
  };
  return __jsx(CardHeadingcontainer, null, copy ? __jsx(H2Styled, null, copy) : __jsx(React.Fragment, null, __jsx(H2Styled, null, "Earn\xA0"), __jsx(H2Styled, null, __jsx(PointsStyled, null, formatPoints(actualPoints)), "\xA0", actualPoints > 1 ? 'points' : 'point', "\xA0", prevPoints && prevPoints !== actualPoints && __jsx(React.Fragment, null, __jsx(WasPointsStyled, null, "was ", formatPoints(prevPoints)), __jsx("span", null, "\xA0"))), __jsx(H2Styled, null, perValue ? "per $".concat(perValue) : __jsx(React.Fragment, null, "\xA0"))));
};
CardHeading.defaultProps = {
  actualPoints: null,
  prevPoints: null,
  perValue: null,
  copy: ''
};
export default CardHeading;