var __jsx = React.createElement;
/* eslint-disable max-len */
import React from 'react';
import Markdown from 'react-markdown';
import { TermsContainer, Heading, Copy, Link } from "./style";
export var TermsLink = function TermsLink(_ref) {
  var children = _ref.children,
    href = _ref.href,
    title = _ref.title;
  return __jsx("span", null, __jsx(Link, {
    target: "_blank",
    rel: "noopener noreferrer",
    href: href,
    title: title
  }, children));
};
var renderDefaultCopy = function renderDefaultCopy() {
  return __jsx(React.Fragment, null, __jsx("p", null, "*To get Qantas Points with Everyday Rewards, you must be a member of Everyday Rewards and choose to \u2018convert to Qantas Points\u2019. Every 2,000 Everyday Rewards points collected will automatically be converted to 1,000 Qantas Points within 24 hours. Conversion will take place in multiples of 2,000 Everyday Rewards points only. Any Everyday Rewards points that are less than a full 2,000 will remain in your account for future use."), __jsx("p", null, "#Everyday Rewards members who have chosen to convert their Everyday Rewards points to Qantas Points will collect a minimum of 1 Everyday Rewards point for every dollar spent excluding smoking products, gift cards (including Woolworths WISH Gift Cards, e-gift cards and iTunes), mobile recharge, Woolworths Mobile, travel cards, tickets, cash outs, delivery charges, donations, Carpet Care, lottery, Pre-order Kiosks, BIG W Photos, BIG W eBay, lay-by fees and purchases using a Country Age Pension Fuel Card, a charge account, or purchases on an ineligible AmpolCard account, when you shop in-store or online at Woolworths, BIG W, BWS, EG Ampol (operated by EG Australia) or Ampol fuel sites."), __jsx("p", null, "Please note that you cannot collect Everyday Rewards points at Ampol and EG Ampol Australia sites located in Tasmania. You cannot currently enjoy your Everyday Rewards dollars at any participating Ampol locations in mainland Australia or in Tasmania or any EG Ampol sites in Tasmania. Additional exclusions for participating Ampol locations include: any services AdBlue, StarCash, AmpolCash, tolls and petrol purchases of more than $150. The full Ampol exclusions list is available at\xA0", __jsx(TermsLink, {
    href: "https://www.everyday.com.au/terms",
    title: "Everyday Rewards Terms"
  }, "everyday.com.au/terms"), ".\xA0 There are a small number of purchases on which you won't collect Everyday Rewards points. For details, see\xA0", __jsx(TermsLink, {
    href: "https://www.woolworthsrewards.com.au/support.html?id=231",
    title: "Woolworths Terms"
  }, "this FAQ")), __jsx("p", null, "^Based on data from Everyday Rewards members who have opted into redeeming Qantas Points from 1 January 2023 - 31 December 2023. Members who are boosting offers, scanning and shopping earn 6x more Everyday Rewards points than members who do not boost offers before shopping and scanning."));
};
var Terms = function Terms(_ref2) {
  var _page$footer, _page$footer$;
  var page = _ref2.page;
  var _ref3 = (page === null || page === void 0 ? void 0 : (_page$footer = page.footer) === null || _page$footer === void 0 ? void 0 : (_page$footer$ = _page$footer[0]) === null || _page$footer$ === void 0 ? void 0 : _page$footer$.fields) || {},
    title = _ref3.title,
    copy = _ref3.copy;
  var DEFAULT_TITLE = 'Important information about Everyday Rewards';
  return __jsx(TermsContainer, {
    veryWideView: true
  }, __jsx(Heading, null, title || DEFAULT_TITLE), __jsx(Copy, null, copy ? __jsx(Markdown, {
    components: {
      a: TermsLink
    }
  }, copy) : renderDefaultCopy()));
};
export default Terms;