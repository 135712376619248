import _defineProperty from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
var __jsx = React.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import React from 'react';
import { connect } from 'react-redux';
import { graphql, compose } from 'react-apollo';
import { queries, graphqlMcoActivate } from "../../../../../data/queries";
import { openLoginModal } from "../../../../../reducers/user";
import fixMcoOffers from "../../../../../utils/fixMcoOffers";
import BaseMerchantCard from "../BaseMerchantCard";
import McoLeftSideCard from "./McoLeftSideCard";
import McoRightSideCard from "./McoRightSideCard";
import McoBottomSideCard from "./McoBottomSideCard";
import McoOfferActiveTile from "./McoOfferActiveTile";
import McoDebitCardDisclaimer from "./McoDebitCardDisclaimer/McoDebitCardDisclaimer";
import { getMcoOffer } from "./utils/getMcoOffer";
import { getInitialContent, buildContentModel, buildMcoContent } from "./utils/buildContent";
import { getVisaAmexEnrolmentFlag } from "../../../../../lib/optimizelyFlagUtils";
var NotLoggedInOrLinkedTile = function NotLoggedInOrLinkedTile(_ref) {
  var slug = _ref.slug,
    user = _ref.user,
    renderLoginModal = _ref.renderLoginModal,
    flags = _ref.flags;
  var content = buildContentModel(user, flags);
  var showFooterCard = content.isLogin && !content.isLinkedMcard && content.debitCardDisclaimer;
  return __jsx(BaseMerchantCard, {
    slug: slug,
    leftSide: __jsx(McoLeftSideCard, {
      content: content
    }),
    rightSide: __jsx(McoRightSideCard, {
      content: content
    }),
    footerCardCaption: showFooterCard ? __jsx(McoDebitCardDisclaimer, {
      cardContent: content.debitCardDisclaimer
    }) : null
  }, __jsx(McoBottomSideCard, {
    content: content,
    renderLoginModal: renderLoginModal,
    flags: flags
  }));
};
var EmptyMcoOfferTile = function EmptyMcoOfferTile(_ref2) {
  var slug = _ref2.slug,
    user = _ref2.user,
    merchant = _ref2.merchant,
    renderLoginModal = _ref2.renderLoginModal,
    flags = _ref2.flags;
  var content = buildContentModel(user, flags);
  return __jsx(BaseMerchantCard, {
    slug: slug,
    leftSide: __jsx(McoLeftSideCard, {
      content: getInitialContent(flags)
    }),
    rightSide: __jsx(McoRightSideCard, {
      merchant: merchant,
      content: content,
      isNoOffer: true
    })
  }, __jsx(McoBottomSideCard, {
    content: content,
    handleCardButton: renderLoginModal,
    isNoOffer: true
  }));
};
export var McoMerchantCard = function McoMerchantCard(props) {
  var mcoOffer = props.mcoOffer,
    user = props.user;
  var isVisaAmexEnabled = getVisaAmexEnrolmentFlag();
  var flags = {
    isVisaAmexEnabled: isVisaAmexEnabled
  };
  if (!user.canDisplayTile || !user.isLinkedMcard) {
    return __jsx(NotLoggedInOrLinkedTile, _objectSpread(_objectSpread({}, props), {}, {
      flags: flags
    }));
  }
  if (!mcoOffer) {
    return __jsx(EmptyMcoOfferTile, _objectSpread(_objectSpread({}, props), {}, {
      flags: flags
    }));
  }
  var newProps = _objectSpread(_objectSpread({}, props), {}, {
    content: buildMcoContent(mcoOffer, user, flags)
  });
  return __jsx(McoOfferActiveTile, newProps);
};
McoMerchantCard.defaultProps = {
  user: {},
  // eslint-disable-line
  mcoOffers: [] // eslint-disable-line
};
var mapStateToProps = function mapStateToProps(_ref3) {
  var user = _ref3.user;
  return {
    user: _objectSpread(_objectSpread({}, user), {}, {
      canDisplayTile: user.authenticated || user.authorized,
      isLinkedMcard: !!user.enrolled
    })
  };
};
var mapDispatchToProps = {
  renderLoginModal: function renderLoginModal() {
    return openLoginModal(window.location.href);
  }
};
export default compose(connect(mapStateToProps, mapDispatchToProps), graphql(queries.getMcoOffers, {
  skip: function skip(_ref4) {
    var _ref4$user = _ref4.user,
      authenticated = _ref4$user.authenticated,
      authorized = _ref4$user.authorized;
    return !authenticated && !authorized;
  },
  props: function props(_ref5) {
    var data = _ref5.data,
      merchant = _ref5.ownProps.merchant;
    return _objectSpread(_objectSpread({}, data), {}, {
      mcoOffer: getMcoOffer({
        mcoOffers: fixMcoOffers(data.mcoOffers),
        merchant: merchant
      })
    });
  },
  options: function options() {
    return {
      fetchPolicy: 'cache-and-network',
      ssr: false
    };
  }
}), graphqlMcoActivate())(McoMerchantCard);