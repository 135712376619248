var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Modal, Button, ArrowLink } from "../../_global";
import { fontSizes, customFonts } from "../../../constants/css";
import { closeCookieWarning } from "../../../reducers/shop";
var Container = styled.div.withConfig({
  displayName: "CookieWarning__Container",
  componentId: "sc-11ogoz9-0"
})(["text-align:center;margin:3rem 0 2rem;padding:0 1.5rem;h2{font-weight:normal;font-size:", ";}"], fontSizes.xl);
var MainTitle = styled.h2.withConfig({
  displayName: "CookieWarning__MainTitle",
  componentId: "sc-11ogoz9-1"
})(["font-size:30px;font-family:", ";"], customFonts.ciutadellaMed);
var DismissBtn = styled(Button).withConfig({
  displayName: "CookieWarning__DismissBtn",
  componentId: "sc-11ogoz9-2"
})(["margin:0.5rem 0;"]);
var MerchantLogo = styled.img.withConfig({
  displayName: "CookieWarning__MerchantLogo",
  componentId: "sc-11ogoz9-3"
})(["width:80px;"]);
var CookieWarning = function CookieWarning(_ref) {
  var showWarning = _ref.showWarning,
    closeWarning = _ref.closeWarning,
    merchantName = _ref.merchantName,
    merchantLogo = _ref.merchantLogo;
  return __jsx(Modal, {
    show: showWarning,
    onClose: closeWarning
  }, __jsx(Container, null, __jsx(MainTitle, null, "We've noticed your cookies are disabled "), __jsx(MerchantLogo, {
    src: merchantLogo
  }), __jsx("p", null, "You'll need to enable cookies so we can track your Qantas Points.", __jsx("br", null), "Once this is done you will then be redirected", merchantName && __jsx("span", null, " to ", merchantName), "."), __jsx(DismissBtn, {
    primary: true,
    onPress: closeWarning
  }, "Okay, got it!"), __jsx("p", null, __jsx(ArrowLink, {
    href: "https://www.qantas.com/au/en/support/manage-cookies.html",
    text: "How to activate my cookies?",
    isExternal: true
  }))));
};
var mapStateToProps = function mapStateToProps(_ref2) {
  var showCookieWarning = _ref2.shop.showCookieWarning;
  return {
    showWarning: showCookieWarning
  };
};
var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    closeWarning: function closeWarning() {
      return dispatch(closeCookieWarning());
    }
  };
};
CookieWarning.defaultProps = {
  showWarning: false,
  closeWarning: function closeWarning() {},
  merchantName: '',
  merchantLogo: ''
};
export default connect(mapStateToProps, mapDispatchToProps)(CookieWarning);