var __jsx = React.createElement;
import React from 'react';
import CardErrorMessage from "../../BaseMerchantCard/CardErrorMessage";
import { ErrorMessageCopy, ErrorMessageLink } from "./style";
var OlmErrorMessage = function OlmErrorMessage(_ref) {
  var merchantName = _ref.merchantName;
  return __jsx(CardErrorMessage, {
    title: "Uh oh. Tracking is currently down."
  }, __jsx(ErrorMessageCopy, null, "We are working with ", merchantName, " so that you can continue earning Qantas Points on your purchases. In the meantime, we have an amazing selection of\xA0", __jsx(ErrorMessageLink, {
    href: "/all-stores",
    rel: "noopener noreferrer"
  }, "over 450 brands"), ", so chances are we have something that's perfect for you."));
};
export default OlmErrorMessage;