var __jsx = React.createElement;
/** @format */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'react-apollo';
import _ from 'lodash';
import { FavouriteIcon } from "../../_global/Icons";
import { toggleFavourite } from "../../../reducers/user";
import { merchantProps } from "../Detail/merchantProps";
import { MerchantContainer, MerchantBanner, MerchantNameWrapper, MerchantTitle } from "../Style/index";
import BonusBanner from "./BonusBanner";
var initialState = {
  isFavourite: false
};
var TopBanner = function TopBanner(props) {
  var merchant = props.merchant,
    hasPermissionToLike = props.hasPermissionToLike,
    isFavourite = props.isFavourite;
  var _useState = useState(initialState),
    state = _useState[0],
    setState = _useState[1];
  useEffect(function () {
    if (merchant && merchant.merchantId) {
      setState({
        isFavourite: isFavourite
      });
    }
  }, [isFavourite]);
  var toggleFavouriteIcon = function toggleFavouriteIcon() {
    props.toggleFavourite(merchant.merchantId);
    setState({
      isFavourite: !state.isFavourite
    });
  };
  return __jsx(MerchantContainer, {
    wideView: true
  }, __jsx(MerchantBanner, null, __jsx("div", null, __jsx(MerchantNameWrapper, null, __jsx(MerchantTitle, {
    itemProp: "name"
  }, merchant.merchantName), hasPermissionToLike && __jsx(FavouriteIcon, {
    onClick: toggleFavouriteIcon,
    isFavourite: state.isFavourite
  }))), __jsx(BonusBanner, {
    merchant: merchant
  })));
};
var mapStateToProps = function mapStateToProps(_ref, _ref2) {
  var _ref$user = _ref.user,
    authenticated = _ref$user.authenticated,
    authorized = _ref$user.authorized,
    favourites = _ref$user.favourites;
  var merchant = _ref2.merchant;
  var merchantId = _.get(merchant, 'merchantId', '');
  var isFavourite = (favourites || {})[merchantId];
  var hasPermissionToLike = authenticated || authorized;
  return {
    hasPermissionToLike: hasPermissionToLike,
    isFavourite: isFavourite
  };
};
var mapDispatchToProps = {
  toggleFavourite: toggleFavourite
};
export default compose(connect(mapStateToProps, mapDispatchToProps))(TopBanner);