import styled from 'styled-components';
import { Button } from "../../../../../_global";
export var CardFooterContainer = styled.div.withConfig({
  displayName: "style__CardFooterContainer",
  componentId: "sc-1cqq3bq-0"
})(["display:flex;flex-direction:column;align-items:flex-start;padding:0 15px;@media ", "{flex-direction:row-reverse;justify-content:space-between;align-items:center;position:fixed;top:68%;width:88%;right:5%;padding:0;height:44px;}"], function (_ref) {
  var theme = _ref.theme;
  return theme.responsiveQuery.desktop;
});
export var CardButton = styled(Button).attrs({
  name: function name(props) {
    return props.celebrusName || null;
  }
}).withConfig({
  displayName: "style__CardButton",
  componentId: "sc-1cqq3bq-1"
})(["display:block;width:100%;margin:19px 0 10px 0;@media ", "{display:flex;align-self:center;justify-content:center;max-width:240px;height:44px;margin:0;}"], function (_ref2) {
  var theme = _ref2.theme;
  return theme.responsiveQuery.desktop;
});
export var CardTermsButtonContainer = styled.div.withConfig({
  displayName: "style__CardTermsButtonContainer",
  componentId: "sc-1cqq3bq-2"
})(["display:flex;flex-direction:", ";width:100%;margin-top:", ";justify-content:space-between;@media ", "{margin-top:0;flex-direction:column;align-items:flex-start;}"], function (props) {
  return props.isExclusions ? 'row-reverse' : 'row';
}, function (props) {
  return !props.callToAction && '76px';
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.responsiveQuery.desktop;
});