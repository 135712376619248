export var howItWorksTileContent = [{
  howLogo: '/static/directContentForm/edit-icon.svg',
  howTitle: ' Choose Qantas Points',
  howCopy: 'Choose Qantas Points as your Rewards choice with Everyday Rewards. Not an Everyday Rewards member?',
  rightIcon: '/static/icons/ico_next_light.svg',
  joinNowLink: '/partner-host?id=null&ct=join'
}, {
  howLogo: '/static/directContentForm/scan-card.svg',
  howTitle: 'Shop and Scan',
  howCopy: 'Shop and scan your Everyday Rewards Card every time you shop in-store or online at Woolworths, BIG W, BWS and many more.',
  rightIcon: '/static/icons/ico_next_light.svg'
}, {
  howLogo: '/static/logos/qantas-logo-transparent.svg',
  howTitle: 'Earn',
  howCopy: 'Once you reach 2,000 Everyday Rewards points, this will automatically convert to 1,000 Qantas Points.'
}];