import styled from 'styled-components';
export var CardFlagContainer = styled.div.withConfig({
  displayName: "style__CardFlagContainer",
  componentId: "sc-lhn2ml-0"
})(["display:flex;max-width:300px;justify-content:flex-start;align-items:center;position:absolute;"]);
export var CardFlagStyled = styled.div.withConfig({
  displayName: "style__CardFlagStyled",
  componentId: "sc-lhn2ml-1"
})(["background:", ";display:flex;padding:2px 8px;border-top-left-radius:6px;border-bottom-right-radius:10px;height:26px;align-items:center;"], function (props) {
  return props.bgColor;
});
export var CardFlagIcon = styled.img.withConfig({
  displayName: "style__CardFlagIcon",
  componentId: "sc-lhn2ml-2"
})(["width:16px;height:16px;margin-right:10px;"]);
export var CardFlagLabel = styled.p.withConfig({
  displayName: "style__CardFlagLabel",
  componentId: "sc-lhn2ml-3"
})(["font-family:", ";font-size:14px;letter-spacing:1.17px;color:", ";text-transform:uppercase;margin:0;"], function (_ref) {
  var theme = _ref.theme;
  return theme.customFonts.ciutadellaMed;
}, function (props) {
  return props.color;
});
export var CardFlagCopy = styled(CardFlagLabel).withConfig({
  displayName: "style__CardFlagCopy",
  componentId: "sc-lhn2ml-4"
})(["font-family:", ";color:", ";letter-spacing:normal;margin:0;"], function (_ref2) {
  var theme = _ref2.theme;
  return theme.customFonts.ciutadellaReg;
}, function (props) {
  return props.color;
});