import { colours, customFonts, fontSizes, layoutSizes } from "../../constants/css";
import { Container } from "../_global";
import styled from 'styled-components';
export var HowItWorksBannerContainer = styled(Container).withConfig({
  displayName: "style__HowItWorksBannerContainer",
  componentId: "sc-1h1nyxq-0"
})([".slick-dots{display:block;text-align:center;padding-left:0px;margin-bottom:40px;li{position:relative;display:inline-block;width:20px;height:20px;padding:0;cursor:pointer;button{font-size:0;line-height:0;display:block;width:20px;height:20px;padding:5px;cursor:pointer;color:transparent;border:0;outline:0;background:0 0;::before{font-size:24px;line-height:20px;position:absolute;top:0px;left:0px;width:20px;height:20px;content:\"\u2022\";text-align:center;opacity:0.25;color:rgb(0,0,0);}}}}.slick-dots li.slick-active button:before{opacity:1;color:#000;}@media only screen and (min-width:", "){margin:auto;}@media only screen and (min-width :", ") and (max-width :991px){margin:auto;}"], layoutSizes.desktopWidth, layoutSizes.desktopWidth);
export var HowItWorksWrapper = styled.div.withConfig({
  displayName: "style__HowItWorksWrapper",
  componentId: "sc-1h1nyxq-1"
})(["width:100%;min-height:227px;margin:auto;@media only screen and (min-width:", "){min-height:275px;margin:auto;padding:0 15px;}@media only screen and (min-width :", ") and (max-width :991px){min-height:275px;margin:auto;}"], layoutSizes.desktopWidth, layoutSizes.desktopWidth);
export var HowItWorksHeader = styled.div.withConfig({
  displayName: "style__HowItWorksHeader",
  componentId: "sc-1h1nyxq-2"
})(["width:100%;min-height:34px;margin:40px auto 1rem;font-family:", ";font-size:1.625rem;font-weight:500;font-style:normal;font-stretch:normal;line-height:normal;letter-spacing:normal;text-align:center;color:#282b26;@media only screen and (min-width:", "){margin:48px auto 27px;}"], customFonts.ciutadellaMed, layoutSizes.desktopWidth);
export var HowItWorksFlow = styled.div.withConfig({
  displayName: "style__HowItWorksFlow",
  componentId: "sc-1h1nyxq-3"
})(["width:100%;padding-bottom:30px;text-align:center;margin:auto;"]);
export var HowItWorksIconsContainer = styled.div.withConfig({
  displayName: "style__HowItWorksIconsContainer",
  componentId: "sc-1h1nyxq-4"
})(["position:relative;display:flex;justify-content:center;align-items:center;"]);
export var HowItWorksIconContainer = styled.div.withConfig({
  displayName: "style__HowItWorksIconContainer",
  componentId: "sc-1h1nyxq-5"
})(["display:inline-block;border-radius:50%;background-color:", ";display:flex;justify-content:center;align-items:center;width:84px;height:84px;@media only screen and (min-width:", "){width:104px;height:auto;border-radius:50%;display:", "}"], function (props) {
  return props.isContrastIcon ? "".concat(colours.qantasMediumLightGrey) : "".concat(colours.qantasWhite);
}, layoutSizes.desktopWidth, function (props) {
  return !props.showRightArrow && 'flex';
});
export var HowItWorksLogo = styled.img.withConfig({
  displayName: "style__HowItWorksLogo",
  componentId: "sc-1h1nyxq-6"
})(["width:48px;height:48px;@media only screen and (min-width:", "){margin:25px;}"], layoutSizes.desktopWidth);
export var HowItWorksArrowButton = styled.button.withConfig({
  displayName: "style__HowItWorksArrowButton",
  componentId: "sc-1h1nyxq-7"
})(["outline:none;position:absolute;top:23%;right:0;background:none;border:0;@media only screen and (min-width :", "){left:90%;}"], layoutSizes.desktopWidth);
export var NextIcon = styled.img.withConfig({
  displayName: "style__NextIcon",
  componentId: "sc-1h1nyxq-8"
})(["width:48px;@media only screen and (min-width:", "){width:60px;}"], layoutSizes.desktopWidth);
export var HowItWorksInfo = styled.div.withConfig({
  displayName: "style__HowItWorksInfo",
  componentId: "sc-1h1nyxq-9"
})(["max-width:260px;min-height:69px;text-align:center;margin:auto;@media only screen and (min-width:", "){min-height:117px;max-width:386px;}@media only screen and (min-width :", ") and (max-width :991px){min-height:117px;}"], layoutSizes.desktopWidth, layoutSizes.desktopWidth);
export var HowItWorksSubHeaderGroup = styled.div.withConfig({
  displayName: "style__HowItWorksSubHeaderGroup",
  componentId: "sc-1h1nyxq-10"
})(["width:100%;height:21px;margin:auto;margin-bottom:1rem;"]);
export var HowItWorksSubHeader = styled.div.withConfig({
  displayName: "style__HowItWorksSubHeader",
  componentId: "sc-1h1nyxq-11"
})(["display:inline-block;height:21px;font-family:", ";font-size:", ";text-align:center;color:", ";line-height:1.17;"], customFonts.ciutadellaMed, fontSizes.large, colours.qantasCharcoal);
export var HowItWorksTextGroup = styled.div.withConfig({
  displayName: "style__HowItWorksTextGroup",
  componentId: "sc-1h1nyxq-12"
})(["width:100%;min-height:48px;margin:auto;@media only screen and (min-width:", "){height:81px;}"], layoutSizes.desktopWidth);
export var HowItWorksText = styled.div.withConfig({
  displayName: "style__HowItWorksText",
  componentId: "sc-1h1nyxq-13"
})(["display:inline-block;height:48px;margin:auto;font-family:", ";font-weight:500;font-style:normal;font-stretch:normal;line-height:1.63;text-align:center;font-size:", ";letter-spacing:normal;color:", ";@media only screen and (min-width:", "){height:81px;}"], customFonts.ciutadellaReg, fontSizes.base, colours.qantasCharcoal, layoutSizes.desktopWidth);
export var HowItWorksTiles = styled.div.withConfig({
  displayName: "style__HowItWorksTiles",
  componentId: "sc-1h1nyxq-14"
})(["display:inline-block;"]);