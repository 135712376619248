import React from "react";
var __jsx = React.createElement;
/** @format */
var tooltipIcon = "/static/icons/icon-tip.svg";
var boostGif = "/static/icons/boost.gif";
import styled from 'styled-components';
import { colours, fontSizes, responsiveQuery } from "../../../constants/css";
var arrow = "/static/link-arrow.svg";
var TooltipWrapper = styled.div.withConfig({
  displayName: "tooltip__TooltipWrapper",
  componentId: "sc-qtwwk0-0"
})(["padding:4rem 0;display:flex;justify-content:center;@media only screen and ", "{flex-direction:column-reverse;align-items:center;}"], responsiveQuery.mobileAndTablet);
var TooltipContainer = styled.div.withConfig({
  displayName: "tooltip__TooltipContainer",
  componentId: "sc-qtwwk0-1"
})(["width:48rem;padding-left:8rem;display:flex;flex-direction:column;align-items:flex-start;@media only screen and ", "{align-items:center;width:38rem;padding:1rem 0rem;}@media only screen and ", "{padding:1rem;width:100%;}"], responsiveQuery.mobileAndTablet, responsiveQuery.mobile);
var TooltipIcon = styled.img.withConfig({
  displayName: "tooltip__TooltipIcon",
  componentId: "sc-qtwwk0-2"
})(["width:4rem;height:4rem;"]);
var TooltipTitle = styled.h2.withConfig({
  displayName: "tooltip__TooltipTitle",
  componentId: "sc-qtwwk0-3"
})(["font-size:1.625rem;margin:1rem 0;line-height:2.25rem;font-weight:bold;@media only screen and ", "{text-align:center;}"], responsiveQuery.mobileAndTablet);
var Tooltipdescription = styled.p.withConfig({
  displayName: "tooltip__Tooltipdescription",
  componentId: "sc-qtwwk0-4"
})(["font-size:", ";margin:0 0 1rem 0;line-height:1.5;color:", ";@media only screen and ", "{text-align:center;}"], fontSizes.large, colours.qantasCharcoal, responsiveQuery.mobileAndTablet);
var Arrow = styled.i.withConfig({
  displayName: "tooltip__Arrow",
  componentId: "sc-qtwwk0-5"
})(["display:inline-block;background:url(", ");background-repeat:no-repeat;height:9px;width:13px;margin-left:0.5rem;"], arrow);
var Anchor = styled.a.withConfig({
  displayName: "tooltip__Anchor",
  componentId: "sc-qtwwk0-6"
})(["font-size:", ";line-height:1.5;text-decoration:none;"], fontSizes.large);
var BoostGifContainer = styled.div.withConfig({
  displayName: "tooltip__BoostGifContainer",
  componentId: "sc-qtwwk0-7"
})([""]);
var BoostGif = styled.img.withConfig({
  displayName: "tooltip__BoostGif",
  componentId: "sc-qtwwk0-8"
})(["@media only screen and ", "{width:100%;}"], responsiveQuery.mobile);
var content = {
  title: 'Top tip: Earn 6x more Everyday Rewards points by boosting',
  description: "Did you know that members who regularly boost, shop and scan earn 6x more Everyday Rewards points than those who don't?^ \n    Download the Everyday Rewards app and hit 'boost' before you shop. It's the fastest way to maximise your \n    Everyday Rewards points, so you can convert to Qantas Points faster."
};
export var Tooltip = function Tooltip() {
  var title = content.title,
    description = content.description;
  return __jsx(TooltipWrapper, null, __jsx(TooltipContainer, null, __jsx(TooltipIcon, {
    src: tooltipIcon,
    alt: "tooltip icon"
  }), __jsx(TooltipTitle, null, title), __jsx(Tooltipdescription, null, description), __jsx(Anchor, {
    href: "https://www.everyday.com.au/how-it-works/points-boosters.html",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Find out more", __jsx(Arrow, null))), __jsx(BoostGifContainer, null, __jsx(BoostGif, {
    src: boostGif,
    alt: "boost gif"
  })));
};