var __jsx = React.createElement;
import React from 'react';
import Container from "../_global/Container";
import styled from 'styled-components';
import { PageTitle, ErrorWrapper } from "../Outage/style";
import Head from 'next/head';
import { layoutSizes } from "../../constants/css";
var BgDeskTop = "/static/error/404-image-desktop.png";
var BgMobile = "/static/error/404-image-mobile.png";
var BgImage = styled.div.withConfig({
  displayName: "ErrorPage__BgImage",
  componentId: "sc-2v8m2j-0"
})(["background:url('", "') no-repeat;min-height:450px;background-size:contain;@media only screen and (min-width:", "){width:100%;background:url('", "') no-repeat;min-height:950px;background-position:bottom;background-size:cover;}"], BgMobile, layoutSizes.desktopWidth, BgDeskTop);
var ErrorPage = function ErrorPage(_ref) {
  var tabTitle = _ref.tabTitle,
    pageTitle = _ref.pageTitle,
    noCrawl = _ref.noCrawl,
    children = _ref.children;
  return __jsx(ErrorWrapper, null, __jsx(Head, null, __jsx("title", null, tabTitle), noCrawl && __jsx("meta", {
    name: "robots",
    content: "noindex"
  })), __jsx(Container, {
    wideView: true
  }, __jsx(PageTitle, null, pageTitle), children), __jsx(BgImage, null));
};
ErrorPage.defaultProps = {
  noCrawl: false
};
export default ErrorPage;