var __jsx = React.createElement;
import React from 'react';
import { colours } from "../../../../../../constants/css";
var timeIcon = "/static/offers/ico_time.svg";
import CardFlag from "../../BaseMerchantCard/CardFlag";
var McoTopSideCard = function McoTopSideCard(_ref) {
  var content = _ref.content;
  if (content.isExpiredToday) {
    return __jsx(CardFlag, {
      label: "Expires today"
    });
  }
  if (content.isOfferExpired) {
    return __jsx(CardFlag, {
      iconUrl: timeIcon,
      label: "Expired ",
      days: content.expiredDate,
      bgColor: colours.calloutGrey
    });
  }
  return content.offerDaysLeft && __jsx(CardFlag, {
    days: content.offerDaysLeft
  });
};
McoTopSideCard.displayName = 'McoTopSideCard';
export default McoTopSideCard;