var __jsx = React.createElement;
import React from 'react';
var plusIcon = "/static/icons/plus-icon.svg";
import CardIcon from "../../BaseMerchantCard/CardIcon";
import { DirectLeftSideContainerStyled } from "./style";
var DirectLeftSideCard = function DirectLeftSideCard(_ref) {
  var content = _ref.content;
  return __jsx(DirectLeftSideContainerStyled, null, __jsx(CardIcon, {
    iconLabel: content.iconLabel,
    iconList: content.iconList,
    symbolIcon: plusIcon
  }));
};
export default DirectLeftSideCard;