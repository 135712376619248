var __jsx = React.createElement;
import React from 'react';
var ShopPlusIcon = "/static/icons/plus-icon.svg";
import { MethodIconsContainer, MethodLabel, MethodImg, PlusIcon } from "./style";
var PanelShopMethod = function PanelShopMethod(_ref) {
  var methodLabel = _ref.methodLabel,
    methodImgs = _ref.methodImgs,
    methodImgAlt = _ref.methodImgAlt;
  return __jsx(React.Fragment, null, __jsx(MethodLabel, null, methodLabel), __jsx(MethodIconsContainer, null, methodImgs && methodImgs.map(function (imgUrl, index) {
    return methodImgs.length > 1 && imgUrl === methodImgs[methodImgs.length - 1] ? __jsx(React.Fragment, {
      key: "".concat(index, "-").concat(methodImgAlt)
    }, __jsx(PlusIcon, {
      src: ShopPlusIcon,
      alt: "Shop plus icon"
    }), __jsx(MethodImg, {
      src: imgUrl,
      alt: methodImgAlt
    })) : __jsx(MethodImg, {
      key: "".concat(index, "-").concat(methodImgAlt),
      src: imgUrl,
      alt: methodImgAlt
    });
  })));
};
PanelShopMethod.defaultProps = {
  methodImgAlt: 'Shop method icons'
};
export default PanelShopMethod;