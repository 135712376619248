var __jsx = React.createElement;
import React from 'react';
import Link from 'next/link';
import ErrorPage from "../components/ErrorPage";
var ErrorPageNotFound = function ErrorPageNotFound(_ref) {
  var statusCode = _ref.statusCode;
  var title = statusCode === 404 ? 'Sorry, we can’t find the page you’re looking for' : 'An unexpected error has occurred';
  return __jsx(ErrorPage, {
    tabTitle: "Page Error | Qantas Shopping",
    pageTitle: title
  }, __jsx("p", null, "Try one of the links below"), __jsx("p", null, __jsx(Link, {
    href: "/"
  }, __jsx("a", null, "Qantas Shopping homepage"))), __jsx("p", null, __jsx(Link, {
    href: "/all-stores"
  }, __jsx("a", null, "All Stores"))));
};
ErrorPageNotFound.defaultProps = {
  statusCode: null
};
ErrorPageNotFound.getInitialProps = function (_ref2) {
  var res = _ref2.res,
    err = _ref2.err;
  var statusCode = res && res.statusCode ? err && err.statusCode : null;
  return {
    statusCode: statusCode
  };
};
export default ErrorPageNotFound;