var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import Markdown from 'react-markdown';
import isExternalLink from "../../../utils/isExternalLink";
import Link from 'next/link';
import { layoutSizes, responsiveQuery } from "../../../constants/css";
import { CalloutSectionContainer, CalloutImage, CopyContainer, CallToActionButton } from "../../Campaign/style";
var MultipleCalloutContainer = styled.div.withConfig({
  displayName: "MultipleCallout__MultipleCalloutContainer",
  componentId: "sc-141ur05-0"
})(["display:flex;flex-direction:column;> div{margin:0 0 1.5rem 0;border-radius:4px;box-shadow:0 1px 1px 0 #00000019;border:solid 1px #e8e8e8;&:last-child{margin-bottom:0;}", "{min-height:10rem;height:auto;width:100%;background-size:cover;background-position:top;}}@media only screen and (min-width:", "){justify-content:space-between;flex-direction:row;> div{display:flex;flex-direction:column;justify-content:space-between;width:", ";margin-bottom:0;", "{height:", ";}", "{display:flex;flex-direction:column;justify-content:space-between;height:", ";padding:1rem;> h2{margin:0;}}&:last-child{padding-right:0;}}}@media only screen and ", "{> div{", "{height:", ";}", "{height:", ";}}}"], CalloutImage, layoutSizes.mobileWidth, function (props) {
  return "calc(".concat(100 / props.calloutCount, "% - 15px)");
}, CalloutImage, function (props) {
  return props.calloutCount === 2 ? '200px' : '135px';
}, CopyContainer, function (props) {
  return props.calloutCount === 2 ? 'calc(100% - 200px)' : 'calc(100% - 135px)';
}, responsiveQuery.tabletAndDesktop, CalloutImage, function (props) {
  return props.calloutCount === 2 ? '310px' : '210px';
}, CopyContainer, function (props) {
  return props.calloutCount === 2 ? 'calc(100% - 310px)' : 'calc(100% - 210px)';
});
var href = function href(calloutLink) {
  return isExternalLink(calloutLink);
};
var MultipleCallout = function MultipleCallout(_ref) {
  var merchantCallouts = _ref.merchantCallouts;
  return __jsx(CalloutSectionContainer, {
    wideView: true
  }, __jsx(MultipleCalloutContainer, {
    calloutCount: merchantCallouts.length
  }, merchantCallouts.map(function (_ref2, i) {
    var callToAction = _ref2.callToAction,
      title = _ref2.title,
      description = _ref2.description,
      calloutImage = _ref2.calloutImage,
      linkUrl = _ref2.linkUrl;
    return __jsx("div", {
      key: i
    }, href(linkUrl) ? __jsx("a", {
      href: linkUrl,
      target: "_blank",
      rel: "noopener noreferrer"
    }, __jsx(CalloutImage, {
      title: title,
      calloutImage: calloutImage
    })) : __jsx(Link, {
      href: href(linkUrl) ? linkUrl : "/".concat(linkUrl)
    }, __jsx(CalloutImage, {
      title: title,
      calloutImage: calloutImage
    })), __jsx(CopyContainer, null, title && __jsx("h2", null, title), description && __jsx(Markdown, null, description), href(linkUrl) ? __jsx("a", {
      href: linkUrl,
      target: "_blank",
      rel: "noopener noreferrer"
    }, __jsx(CallToActionButton, {
      outline: true,
      primary: true
    }, callToAction)) : __jsx(CallToActionButton, {
      outline: true,
      primary: true,
      pageLink: linkUrl
    }, callToAction)));
  })));
};
MultipleCallout.defaultProps = {
  merchantCallouts: []
};
export default MultipleCallout;