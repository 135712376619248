import _extends from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/extends";
var __jsx = React.createElement;
import React from 'react';
import _ from 'lodash';
var plusIcon = "/static/icons/plus-icon.svg";
import { McoLeftSideCardStyled, CardIconLabel, RedemptionIconsContainer, RedemptionIcon, PlusIcon, CardLogo, RedemptionContainer, SingleCardLogo } from "./style";
var McoLeftSideCard = function McoLeftSideCard(_ref) {
  var _ref$content = _ref.content,
    iconLabel = _ref$content.iconLabel,
    iconList = _ref$content.iconList;
  var logoIcon = _.last(iconList);
  var redemptionIcons = _.initial(iconList);
  var hasRedemptions = redemptionIcons.length > 0;
  var hasMultiRedemptions = redemptionIcons.length > 1;
  return __jsx(McoLeftSideCardStyled, {
    hasRedemptions: hasRedemptions
  }, __jsx(React.Fragment, null, hasRedemptions && __jsx(React.Fragment, null, __jsx(RedemptionContainer, null, iconLabel && __jsx(CardIconLabel, null, iconLabel), __jsx(RedemptionIconsContainer, null, redemptionIcons.map(function (imgProps, index) {
    return __jsx(RedemptionIcon, _extends({}, imgProps, {
      hasMultiRedemptions: hasMultiRedemptions,
      key: "".concat(index, "-").concat(imgProps.alt)
    }));
  }))), __jsx(PlusIcon, {
    hasMultiRedemptions: hasMultiRedemptions,
    src: plusIcon,
    alt: "Plus"
  })), __jsx("picture", null, logoIcon.desktopSrc && __jsx("source", {
    srcSet: logoIcon.desktopSrc,
    media: "(min-width: 768px)"
  }), hasRedemptions ? __jsx(CardLogo, logoIcon) : __jsx(SingleCardLogo, logoIcon))));
};
export default McoLeftSideCard;