var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { Container } from "../../_global";
import { fontSizes, colours } from "../../../constants/css";
import Markdown from 'react-markdown';
var TermsWrapper = styled.section.withConfig({
  displayName: "ImportantInfoFooter__TermsWrapper",
  componentId: "sc-1b5c97q-0"
})(["background-color:", ";color:", ";padding:2rem 0;"], colours.qantasIceWhite, colours.qantasTermsGrey);
var TermsContainer = styled(Container).withConfig({
  displayName: "ImportantInfoFooter__TermsContainer",
  componentId: "sc-1b5c97q-1"
})(["display:flex;flex-direction:column;align-items:flex-start;font-size:", ";line-height:1.58;"], fontSizes.xs);
var ImportantInfoFooter = function ImportantInfoFooter(props) {
  return __jsx(TermsWrapper, null, __jsx(TermsContainer, {
    veryWideView: true
  }, __jsx(Markdown, null, props.merchantImportantInfo)));
};
export default ImportantInfoFooter;