import styled, { css } from 'styled-components';
export var McoLeftSideCardStyled = styled.div.withConfig({
  displayName: "style__McoLeftSideCardStyled",
  componentId: "sc-ypa12r-0"
})(["padding:40px 15px 16px 0px;display:flex;flex-direction:row;justify-content:center;align-items:center;@media ", "{padding:0;min-width:", ";}"], function (_ref) {
  var theme = _ref.theme;
  return theme.responsiveQuery.desktop;
}, function (_ref2) {
  var hasRedemptions = _ref2.hasRedemptions;
  return hasRedemptions ? '244px' : ' 213px';
});
export var RedemptionContainer = styled.div.withConfig({
  displayName: "style__RedemptionContainer",
  componentId: "sc-ypa12r-1"
})(["display:flex;justify-content:center;align-items:center;flex-direction:column;"]);
export var CardIconLabel = styled.p.withConfig({
  displayName: "style__CardIconLabel",
  componentId: "sc-ypa12r-2"
})(["font-size:12px;line-height:1;text-transform:uppercase;color:", ";margin:0 0 6px 0;white-space:nowrap;@media ", "{font-size:", ";line-height:0.81;margin:0 0 10px 0;}"], function (_ref3) {
  var theme = _ref3.theme;
  return theme.colours.qantasCharcoal;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.responsiveQuery.desktop;
}, function (_ref5) {
  var theme = _ref5.theme;
  return theme.fontSizes.base;
});
export var RedemptionIconsContainer = styled.div.withConfig({
  displayName: "style__RedemptionIconsContainer",
  componentId: "sc-ypa12r-3"
})(["display:flex;justify-content:left;align-items:center;"]);
export var RedemptionIcon = styled.img.withConfig({
  displayName: "style__RedemptionIcon",
  componentId: "sc-ypa12r-4"
})(["display:block;", ""], function (_ref6) {
  var hasMultiRedemptions = _ref6.hasMultiRedemptions;
  if (hasMultiRedemptions) {
    return css(["width:35px;height:35px;&+img{margin-left:5px;}@media ", "{width:48px;height:48px;}"], function (_ref7) {
      var theme = _ref7.theme;
      return theme.responsiveQuery.desktop;
    });
  }
  return css(["width:46px;height:46px;@media ", "{width:64px;height:64px;}"], function (_ref8) {
    var theme = _ref8.theme;
    return theme.responsiveQuery.desktop;
  });
});
export var PlusIcon = styled.img.withConfig({
  displayName: "style__PlusIcon",
  componentId: "sc-ypa12r-5"
})(["width:8px;height:8px;margin:", " @media ", "{width:10px;height:10px;margin:22px ", "}"], function (_ref9) {
  var hasMultiRedemptions = _ref9.hasMultiRedemptions;
  return hasMultiRedemptions ? '18px 5px 0 0;' : '18px 8px 0 10px;';
}, function (_ref10) {
  var theme = _ref10.theme;
  return theme.responsiveQuery.desktop;
}, function (_ref11) {
  var hasMultiRedemptions = _ref11.hasMultiRedemptions;
  return hasMultiRedemptions ? '10px 0 0;' : '15px 0 15px;';
});
export var SingleCardLogo = styled.img.withConfig({
  displayName: "style__SingleCardLogo",
  componentId: "sc-ypa12r-6"
})(["display:block;", ""], function (_ref12) {
  var type = _ref12.type;
  if (type === 'stackedLogos') {
    return css(["width:auto;height:37px;@media ", "{width:50px;height:auto;}"], function (_ref13) {
      var theme = _ref13.theme;
      return theme.responsiveQuery.desktop;
    });
  }
  return css(["width:auto;height:37px;@media ", "{width:84px;height:auto;}"], function (_ref14) {
    var theme = _ref14.theme;
    return theme.responsiveQuery.desktop;
  });
});
export var CardLogo = styled.img.withConfig({
  displayName: "style__CardLogo",
  componentId: "sc-ypa12r-7"
})(["display:block;width:42px;height:auto;margin-top:18px;@media ", "{width:52px;}", ""], function (_ref15) {
  var theme = _ref15.theme;
  return theme.responsiveQuery.desktop;
}, function (_ref16) {
  var type = _ref16.type;
  if (type === 'stackedLogos') {
    return css(["width:25px;@media ", "{width:36px;}"], function (_ref17) {
      var theme = _ref17.theme;
      return theme.responsiveQuery.desktop;
    });
  }
  return '';
});