var __jsx = React.createElement;
import React from 'react';
import CardSubHeading from "../../BaseMerchantCard/CardSubHeading";
import { McoPromptMessageContainer, McoPromptHeading, McoPromptSubheadingContainer } from "./style";
var McoPromptMessage = function McoPromptMessage(_ref) {
  var heading = _ref.heading,
    subHeading = _ref.subHeading;
  return __jsx(McoPromptMessageContainer, {
    isSubHeading: subHeading
  }, __jsx(McoPromptHeading, {
    isSubHeading: subHeading
  }, heading), subHeading && __jsx(McoPromptSubheadingContainer, null, __jsx(CardSubHeading, {
    copy: subHeading
  })));
};
export default McoPromptMessage;