var __jsx = React.createElement;
import React from 'react';
import CardIcon from "../../BaseMerchantCard/CardIcon";
import { OlmLeftSideContainerStyled } from "./style";
var OlmLeftSideCard = function OlmLeftSideCard(_ref) {
  var merchant = _ref.merchant,
    content = _ref.content;
  return __jsx(OlmLeftSideContainerStyled, null, __jsx(CardIcon, {
    iconLabel: content.iconLabel,
    iconList: content.iconList
  }));
};
export default OlmLeftSideCard;