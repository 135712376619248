var __jsx = React.createElement;
import React from 'react';
import { Icon } from "../../../../../_global";
import Markdown from 'react-markdown';
import { CloseButton, CardTermsStyled, TermsHeaderContainer, TermInfoContainer, TermLabelContainer, IconStyled, TermLabel, TermsCopy } from "./style";
var CardTerms = function CardTerms(_ref) {
  var termsLabel = _ref.termsLabel,
    termsCopy = _ref.termsCopy,
    bonusTermCopy = _ref.bonusTermCopy,
    onClose = _ref.onClose,
    iconUrl = _ref.iconUrl,
    colorLabel = _ref.colorLabel;
  return __jsx(CardTermsStyled, null, __jsx(TermsHeaderContainer, null, __jsx(CloseButton, {
    onClick: onClose,
    "aria-label": "Close info"
  }, __jsx(Icon, {
    iconType: "closeCross",
    size: "base"
  }))), __jsx(TermInfoContainer, null, __jsx(TermLabelContainer, null, iconUrl && __jsx(IconStyled, {
    iconUrl: iconUrl,
    iconalt: "content-icon"
  }), __jsx(TermLabel, {
    headingColor: colorLabel
  }, termsLabel)), bonusTermCopy && __jsx(TermsCopy, null, bonusTermCopy), __jsx(TermsCopy, null, __jsx(Markdown, null, termsCopy))));
};
CardTerms.defaultProps = {
  iconUrl: null,
  colorLabel: '#000',
  bonusTermCopy: null
};
export default CardTerms;