var __jsx = React.createElement;
import React from 'react';
import CardHeading from "../../BaseMerchantCard/CardHeading";
import CardSubHeading from "../../BaseMerchantCard/CardSubHeading";
import { DesktopDisplayOnly } from "../../BaseMerchantCard/style";
import { CardSubHeadingContainer } from "../style";
import { DirectRightSideContainerStyled } from "./style";
var DirectRightSideCard = function DirectRightSideCard(_ref) {
  var tile = _ref.tile;
  return __jsx(DirectRightSideContainerStyled, null, (tile.points || tile.offerHeading) && __jsx(CardHeading, {
    actualPoints: tile.points,
    perValue: tile.perValue,
    copy: tile.offerHeading
  }), __jsx(React.Fragment, null, __jsx(DesktopDisplayOnly, null, __jsx(CardSubHeadingContainer, null, __jsx(CardSubHeading, {
    copy: tile.subtitle
  })))));
};
export default DirectRightSideCard;