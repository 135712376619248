import React from "react";
var __jsx = React.createElement;
import WWMerchantPanel from "../../Woolworths/MerchantPanel";
import { MerchantPanelWrapper, MerchantPanelHeader } from "../Style/index";

// TODO: This code needs to be refactored
var MerchantPanel = function MerchantPanel(_ref) {
  var _ref$content = _ref.content,
    title = _ref$content.title,
    body = _ref$content.body;
  return __jsx(MerchantPanelWrapper, null, __jsx(MerchantPanelHeader, null, title), body.startsWith('<WWMerchantPanel') && __jsx(WWMerchantPanel, null));
};
export default MerchantPanel;