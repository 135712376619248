var __jsx = React.createElement;
import React from 'react';
import { colours } from "../../../../../../constants/css";
import { CardFlagContainer, CardFlagStyled, CardFlagIcon, CardFlagLabel, CardFlagCopy } from "./style";
var CardFlag = function CardFlag(_ref) {
  var label = _ref.label,
    days = _ref.days,
    bgColor = _ref.bgColor,
    iconUrl = _ref.iconUrl,
    color = _ref.color;
  return __jsx(CardFlagContainer, null, __jsx(CardFlagStyled, {
    bgColor: bgColor
  }, iconUrl && __jsx(CardFlagIcon, {
    src: iconUrl,
    alt: "flag-icon"
  }), label && __jsx(CardFlagLabel, {
    color: color
  }, label, "\xA0"), days && __jsx(CardFlagCopy, {
    color: color
  }, days)));
};
CardFlag.defaultProps = {
  label: null,
  bgColor: colours.qantasAqua,
  days: null,
  color: colours.qantasBlack10
};
export default CardFlag;