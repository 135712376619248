var __jsx = React.createElement;
import React from 'react';
import _ from 'lodash';
import ErrorPage from "./_error";
import MerchantDetail from "../components/Merchant/Detail";
import MerchantDirect from "../components/Merchant/Direct";
import RewardsLanding from "../components/Woolworths/RewardsLanding";
var Merchant = function Merchant(_ref) {
  var _ref$slug = _ref.slug,
    slug = _ref$slug === void 0 ? null : _ref$slug,
    _ref$statusCode = _ref.statusCode,
    statusCode = _ref$statusCode === void 0 ? null : _ref$statusCode,
    page = _ref.page,
    ipAddress = _ref.ipAddress;
  if (statusCode && statusCode !== 200) {
    return __jsx(ErrorPage, {
      statusCode: statusCode
    });
  }
  if (slug === 'terrywhite-chemmart') {
    return __jsx(MerchantDirect, {
      slug: slug
    });
  }
  if (slug === 'everyday-rewards') {
    return __jsx(RewardsLanding, {
      ipAddress: ipAddress,
      slug: slug,
      page: page
    });
  }
  return __jsx(MerchantDetail, {
    ipAddress: ipAddress,
    slug: slug
  });
};
Merchant.getInitialProps = function (_ref2) {
  var res = _ref2.res,
    ctx = _ref2.ctx,
    slug = _ref2.query.slug;
  var statusCode = _.get(res, 'statusCode');
  var page = _.get(res, 'locals.page');
  if (!slug) {
    statusCode = 404;
  }
  return {
    slug: slug,
    statusCode: statusCode,
    page: page
  };
};
export default Merchant;