import styled from 'styled-components';
import { Button } from "../../../../../_global";
export var McoBottomSideCardStyled = styled.div.withConfig({
  displayName: "style__McoBottomSideCardStyled",
  componentId: "sc-ij0pkg-0"
})(["display:flex;flex-direction:column;align-items:flex-start;padding:0 15px;margin-top:-0.4em;@media ", "{margin-top:-1em;flex-direction:row-reverse;justify-content:space-between;align-items:center;position:fixed;top:68%;width:88%;right:5%;padding:0;margin-top:0em;}"], function (_ref) {
  var theme = _ref.theme;
  return theme.responsiveQuery.desktop;
});
export var McoButtonContainer = styled.div.withConfig({
  displayName: "style__McoButtonContainer",
  componentId: "sc-ij0pkg-1"
})(["display:flex;width:100%;margin:19px 0px 10px 0;@media ", "{", " justify-content:flex-end;margin:0 0px 0 20px;}"], function (_ref2) {
  var theme = _ref2.theme;
  return theme.responsiveQuery.desktop;
}, function (props) {
  return props.hasStore && 'min-width: 368px;';
});
export var CardButton = styled(Button).withConfig({
  displayName: "style__CardButton",
  componentId: "sc-ij0pkg-2"
})(["display:flex;width:100%;justify-content:center;align-self:center;&:first-child{margin-right:10px;}@media ", "{max-width:240px;height:44px;}"], function (_ref3) {
  var theme = _ref3.theme;
  return theme.responsiveQuery.desktop;
});
export var McoTermsButtonContainer = styled.div.withConfig({
  displayName: "style__McoTermsButtonContainer",
  componentId: "sc-ij0pkg-3"
})(["display:flex;flex-direction:row;width:100%;justify-content:space-between;@media ", "{flex-direction:column-reverse;align-items:flex-start;}"], function (_ref4) {
  var theme = _ref4.theme;
  return theme.responsiveQuery.desktop;
});
export var McoCardPromptContainer = styled.div.withConfig({
  displayName: "style__McoCardPromptContainer",
  componentId: "sc-ij0pkg-4"
})(["display:flex;flex-direction:column;text-align:left;@media ", "{width:100%;}"], function (_ref5) {
  var theme = _ref5.theme;
  return theme.responsiveQuery.desktop;
});