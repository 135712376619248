var __jsx = React.createElement;
import React from 'react';
import CardHeading from "../../BaseMerchantCard/CardHeading";
import CardSubHeading from "../../BaseMerchantCard/CardSubHeading";
import McoPromptMessage from "../McoPromptMessage";
import { DesktopDisplayOnly } from "../../BaseMerchantCard/style";
import { CardSubHeadingContainer } from "../style";
import { McoRightSideContainerStyled } from "./style";
var McoPromptDesktopOnlyView = function McoPromptDesktopOnlyView(_ref) {
  var promptHeading = _ref.promptHeading,
    promptSubHeading = _ref.promptSubHeading;
  return __jsx(DesktopDisplayOnly, null, __jsx(McoRightSideContainerStyled, {
    isPromptMessage: true
  }, __jsx(McoPromptMessage, {
    heading: promptHeading,
    subHeading: promptSubHeading
  })));
};
var McoRightSideCard = function McoRightSideCard(_ref2) {
  var content = _ref2.content,
    isNoOffer = _ref2.isNoOffer;
  if (!content.isLogin) {
    return __jsx(McoPromptDesktopOnlyView, {
      promptHeading: content.loginMsg
    });
  }
  if (!content.isLinkedMcard) {
    return __jsx(McoPromptDesktopOnlyView, {
      promptHeading: content.linkMsg
    });
  }
  if (isNoOffer) {
    return __jsx(McoPromptDesktopOnlyView, {
      promptHeading: content.checkBackMsg,
      promptSubHeading: content.checkBackSubheading
    });
  }
  return __jsx(McoRightSideContainerStyled, null, __jsx(CardHeading, {
    actualPoints: content.actualPoints,
    perValue: content.perValue
  }), __jsx(DesktopDisplayOnly, null, __jsx(CardSubHeadingContainer, null, __jsx(CardSubHeading, {
    copy: content.subHeading
  }))));
};
export default McoRightSideCard;