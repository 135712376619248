var __jsx = React.createElement;
import React from 'react';
import _ from 'lodash';
import { slugify } from "../../../../../../utils/slugify";
import CardSubHeading from "../../BaseMerchantCard/CardSubHeading";
import CardTermsButton from "../../BaseMerchantCard/CardTermsButton";
import { MobileDisplayOnly } from "../../BaseMerchantCard/style";
import { CardSubHeadingContainer } from "../style";
import { CardFooterContainer, CardButton, CardTermsButtonContainer } from "./style";
var OlmBottomSideCard = function OlmBottomSideCard(_ref) {
  var tile = _ref.tile,
    content = _ref.content,
    merchant = _ref.merchant,
    isOutageMode = _ref.isOutageMode,
    handleCardButton = _ref.handleCardButton,
    handleCardFlip = _ref.handleCardFlip,
    handleExclusionsClicked = _ref.handleExclusionsClicked;
  var callToAction = _.get(tile, 'callToActions[0]');
  var buttonNameAttr = "merchant_OM_".concat(merchant.merchantId);
  if (!callToAction) {
    return null;
  }
  return __jsx(CardFooterContainer, null, __jsx(MobileDisplayOnly, null, __jsx(CardSubHeadingContainer, null, __jsx(CardSubHeading, {
    copy: content.subHeading
  }))), !isOutageMode ? __jsx(CardButton, {
    primary: callToAction.primary,
    block: true,
    onPress: handleCardButton,
    name: buttonNameAttr,
    "data-attribute": slugify(callToAction.name),
    "data-brand": merchant.merchantId
  }, callToAction.name) : __jsx(CardButton, {
    disabled: true
  }, "Currently Unavailable"), __jsx(CardTermsButtonContainer, {
    isExclusions: merchant.exclusions
  }, merchant.exclusions && __jsx(CardTermsButton, {
    buttonLabel: content.exclusions.label,
    textColor: content.exclusions.colorLabel,
    onClick: handleExclusionsClicked,
    icon: content.exclusions.iconLabel
  }), __jsx(CardTermsButton, {
    primary: true,
    buttonLabel: content.termsLabel,
    onClick: handleCardFlip
  })));
};
OlmBottomSideCard.defaultProps = {
  isOutageMode: false
};
export default OlmBottomSideCard;