import _slicedToArray from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/slicedToArray";
var __jsx = React.createElement;
/** @format */

import React, { useState, useEffect } from 'react';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import { colours } from "../../../../../constants/css";
var onlineIcon = "/static/merchant/online.png";
var excludeIcon = "/static/icons/ico_exclude.svg";
import { MALL_STATUS } from "../../../../../utils/mallStatus";
import { isChannelForBrowser } from "../../../../../utils/sessionStorageHelper";
import { openLoginModal } from "../../../../../reducers/user";
import { clickToShop, clearClickUrl, openCookieWarning } from "../../../../../reducers/shop";
import { useDecision } from '@optimizely/react-sdk';
import CardFooterCaption from "../BaseMerchantCard/CardFooterCaption";
import BaseMerchantCard from "../BaseMerchantCard";
import OlmErrorMessage from "./OlmErrorMessage";
import OlmTopSideCard from "./OlmTopSideCard";
import OlmLeftSideCard from "./OlmLeftSideCard";
import OlmRightSideCard from "./OlmRightSideCard";
import OlmBottomSideCard from "./OlmBottomSideCard";
import OlmBackSideCard from "./OlmBackSideCard";
import { MerchantCardWrapper } from "../../../Style/index";
import { MobileDisplayOnly } from "../BaseMerchantCard/style";
import CardPebble from "../CardPebble";
import { OPTIMIZELY_FLAGS } from "../../../../../lib/optimizelyClient";
var content = {
  iconLabel: 'Online',
  iconList: [{
    src: onlineIcon,
    alt: 'Online'
  }],
  subHeading: 'when you click through from Qantas Shopping each time you shop',
  termsLabel: 'Terms & Conditions',
  exclusions: {
    label: 'Exclusions',
    colorLabel: '#f4750c',
    // orange,
    iconLabel: excludeIcon
  }
};
var initialState = {
  isFlipped: false,
  isFetchingError: false,
  isOutageMode: false,
  isExclusions: false,
  isPebbleVisible: false
};
var OlmMerchantCard = function OlmMerchantCard(_ref) {
  var slug = _ref.slug,
    clearClick = _ref.clearClick,
    user = _ref.user,
    shop = _ref.shop,
    renderLoginModal = _ref.renderLoginModal,
    renderCookieWarning = _ref.renderCookieWarning,
    merchant = _ref.merchant,
    tile = _ref.tile,
    showPebble = _ref.showPebble,
    bonusRibbons = _ref.bonusRibbons;
  var _useState = useState(initialState),
    state = _useState[0],
    setState = _useState[1];
  var isTrackingDown = !MALL_STATUS.isActive(merchant.merchantStatus);
  var _useDecision = useDecision(OPTIMIZELY_FLAGS.INCENTIVE_NETWORK_OUTAGE),
    _useDecision2 = _slicedToArray(_useDecision, 1),
    isIncentiveNetworksDown = _useDecision2[0].enabled;
  useEffect(function () {
    clearClick();
  }, [user.ffn]);
  useEffect(function () {
    setState({
      isPebbleVisible: showPebble
    });
  }, [showPebble]);
  if (shop.fetchingClickUrl && shop.clickUrlError) {
    setState({
      isFetchingError: true
    });
  }
  var handleCardFlip = function handleCardFlip() {
    setState(function (prevState) {
      return {
        isFlipped: !prevState.isFlipped,
        isPebbleVisible: false
      };
    });
  };
  var handleExclusionsClicked = function handleExclusionsClicked() {
    setState(function (prevState) {
      return {
        isExclusions: true,
        isFlipped: !prevState.isFlipped,
        isPebbleVisible: false
      };
    });
  };
  var handleCardButton = function handleCardButton() {
    try {
      if (window.navigator.cookieEnabled === false) {
        renderCookieWarning();
      }
      if (user.authenticated || user.authorized) {
        if (isChannelForBrowser()) {
          window.open("/shop?id=".concat(merchant.merchantId.replace(/\+/g, '%2B')));
        } else {
          window.location.href = "/shop?id=".concat(merchant.merchantId.replace(/\+/g, '%2B'));
        }
      } else {
        renderLoginModal(window.location.href);
      }
    } catch (e) {
      setState({
        isOutageMode: true
      });
    }
  };
  var onCloseBackCard = function onCloseBackCard() {
    setState({
      isFlipped: false,
      isPebbleVisible: showPebble
    });
  };
  return __jsx(MerchantCardWrapper, null, __jsx(BaseMerchantCard, {
    isFetchingError: state.isFetchingError || isTrackingDown,
    isPebbleVisible: state.isPebbleVisible,
    className: "olm-merchant-card",
    slug: slug,
    topSide: __jsx(OlmTopSideCard, {
      merchant: merchant,
      bonusRibbons: bonusRibbons
    }),
    leftSide: __jsx(OlmLeftSideCard, {
      merchant: merchant,
      content: content
    }),
    rightSide: __jsx(OlmRightSideCard, {
      merchant: merchant,
      content: content,
      isFetchingError: state.isFetchingError || isTrackingDown
    }),
    backSideCard: state.isFlipped && __jsx(OlmBackSideCard, {
      merchant: merchant,
      content: content,
      isExclusions: state.isExclusions,
      onCloseBackCard: onCloseBackCard
    }),
    footerCardCaption: __jsx(CardFooterCaption, {
      days: merchant.discrete_days && merchant.discrete_days + 5 || 90
    }),
    isCardFlipped: state.isFlipped
  }, !state.isFetchingError && !isTrackingDown ? __jsx(OlmBottomSideCard, {
    content: content,
    tile: tile,
    merchant: merchant,
    isOutageMode: state.isOutageMode || isIncentiveNetworksDown,
    handleCardButton: handleCardButton,
    handleCardFlip: handleCardFlip,
    handleExclusionsClicked: handleExclusionsClicked
  }) : __jsx(MobileDisplayOnly, null, __jsx(OlmErrorMessage, {
    merchantName: merchant.merchantName
  }))), __jsx(CardPebble, {
    orColor: colours.qantasLightOrange,
    isPebbleVisible: state.isPebbleVisible,
    showPebble: tile.showPebble
  }));
};
OlmMerchantCard.defaultProps = {
  user: {},
  shop: {},
  showPebble: false,
  bonusRibbons: []
};
var mapStateToProps = function mapStateToProps(_ref2) {
  var user = _ref2.user,
    shop = _ref2.shop;
  return {
    user: user,
    shop: shop
  };
};
var mapDispatchToProps = {
  shopNow: clickToShop,
  renderLoginModal: openLoginModal,
  clearClick: clearClickUrl,
  renderCookieWarning: openCookieWarning
};
export default compose(connect(mapStateToProps, mapDispatchToProps))(OlmMerchantCard);