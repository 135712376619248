var __jsx = React.createElement;
/** @format */

import React from 'react';
var PromoIcon = "/static/merchant/promo-icon.svg";
import { merchantProps } from "../Detail/merchantProps";
import { MerchantCouponContainer, MerchantCouponWrapper } from "../Style/index";
import Coupon from "../Detail/Coupon";
var MerchantCoupon = function MerchantCoupon(props) {
  var merchant = props.merchant;
  var theYear5138 = 99999999999999;
  var parseDateWithNullcheck = function parseDateWithNullcheck(endDate) {
    return endDate ? Date.parse(endDate) : theYear5138;
  };
  var dateAscending = function dateAscending(a, b) {
    return a - b;
  };
  return __jsx(MerchantCouponContainer, {
    wideView: true
  }, __jsx("img", {
    src: PromoIcon,
    alt: "promo icon"
  }), __jsx("h2", null, merchant.merchantName, " promo codes & coupons"), __jsx(MerchantCouponWrapper, null, merchant.coupons.sort(function (a, b) {
    return dateAscending(parseDateWithNullcheck(a.end_date), parseDateWithNullcheck(b.end_date));
  }).map(function (coupon) {
    return __jsx(Coupon, {
      couponId: coupon.id,
      key: coupon.id,
      code: coupon.code,
      title: coupon.name,
      description: coupon.description,
      expiry: coupon.end_date,
      info: coupon.restriction,
      merchant: merchant
    });
  })));
};
export default MerchantCoupon;