import styled from 'styled-components';
export var SubHeading = styled.p.withConfig({
  displayName: "style__SubHeading",
  componentId: "sc-3xvcgo-0"
})(["font-size:", ";width:100%;margin:0;overflow:hidden;text-align:left;color:", ";@media ", "{font-size:", ";}"], function (_ref) {
  var theme = _ref.theme;
  return theme.fontSizes.base;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.colours.qantasCharcoal;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.responsiveQuery.desktop;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.fontSizes.large;
});