import styled from 'styled-components';
export var DirectRightSideContainerStyled = styled.div.withConfig({
  displayName: "style__DirectRightSideContainerStyled",
  componentId: "sc-io7dkh-0"
})(["padding:34px 0 0 15px;@media ", "{display:flex;flex-direction:column;justify-content:center;align-items:flex-start;min-width:380px;padding:0 38px 15px 0;}"], function (_ref) {
  var theme = _ref.theme;
  return theme.responsiveQuery.desktop;
});
export var PointingArrow = styled.img.withConfig({
  displayName: "style__PointingArrow",
  componentId: "sc-io7dkh-1"
})(["width:40px;height:40px;position:fixed;top:50%;right:14%;@media ", "{top:50%;right:27%;width:50px;height:50px;}"], function (_ref2) {
  var theme = _ref2.theme;
  return theme.responsiveQuery.desktop;
});