/**
 * getEarnPoints - Get the earn points when discount type is PERCENTAGE or ABSOLUTE
 * @type {{discount: *, discountType: *, earnRate: *}}
 */
export var getEarnPoints = function getEarnPoints(_ref) {
  var discount = _ref.discount,
    discountType = _ref.discountType,
    earnRate = _ref.earnRate;
  if (discountType === 'PERCENTAGE') {
    return {
      actualPoints: Number(earnRate),
      perValue: 1
    };
  }
  return {
    actualPoints: Number(discount),
    perValue: null
  };
};