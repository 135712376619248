import styled from 'styled-components';
import { ErrorHeading } from "../../BaseMerchantCard/CardErrorMessage/style";
export var ErrorMessageCopy = styled(ErrorHeading).withConfig({
  displayName: "style__ErrorMessageCopy",
  componentId: "sc-txshx0-0"
})(["text-transform:none;letter-spacing:normal;font-family:", ";margin:6px 0 0 0;min-height:7em;overflow:hidden;@media ", "{min-height:5em;}"], function (_ref) {
  var theme = _ref.theme;
  return theme.customFonts.ciutadellaReg;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.responsiveQuery.desktop;
});
export var ErrorMessageLink = styled.a.withConfig({
  displayName: "style__ErrorMessageLink",
  componentId: "sc-txshx0-1"
})(["color:", ";font-family:", ";"], function (_ref3) {
  var theme = _ref3.theme;
  return theme.colours.qantasCharcoal;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.customFonts.ciutadellaMed;
});