var __jsx = React.createElement;
import React from 'react';
import { withRouter } from 'next/router';
import CardSubHeading from "../../BaseMerchantCard/CardSubHeading";
import CardTermsButton from "../../BaseMerchantCard/CardTermsButton";
import { MobileDisplayOnly } from "../../BaseMerchantCard/style";
import { CardSubHeadingContainer } from "../style";
import { McoBottomSideCardStyled, CardButton, McoButtonContainer, McoTermsButtonContainer, McoCardPromptContainer } from "./style";
import McoPromptMessage from "../McoPromptMessage";
var McoPromptView = function McoPromptView(_ref) {
  var promptHeading = _ref.promptHeading,
    promptSubHeading = _ref.promptSubHeading,
    onClickButton = _ref.onClickButton,
    buttonLabel = _ref.buttonLabel,
    redirectUrl = _ref.redirectUrl;
  return __jsx(McoCardPromptContainer, null, __jsx(MobileDisplayOnly, null, __jsx(McoPromptMessage, {
    heading: promptHeading,
    subHeading: promptSubHeading
  })), __jsx(CardButton, {
    primary: true,
    block: true,
    onPress: onClickButton,
    pageLink: redirectUrl
  }, buttonLabel));
};
var McoBottomSideCard = function McoBottomSideCard(_ref2) {
  var content = _ref2.content,
    isNoOffer = _ref2.isNoOffer,
    handleCardButton = _ref2.handleCardButton,
    handleCardFlip = _ref2.handleCardFlip,
    renderLoginModal = _ref2.renderLoginModal,
    router = _ref2.router;
  var renderCardBody = function renderCardBody(bodyContent) {
    var _router$query3, _router$query4;
    if (!content.isLogin) {
      return __jsx(McoPromptView, {
        promptHeading: bodyContent.loginMsg,
        onClickButton: renderLoginModal,
        buttonLabel: "Login"
      });
    }
    if (!content.isLinkedMcard) {
      return __jsx(McoPromptView, {
        promptHeading: bodyContent.linkMsg,
        redirectUrl: "/link-card",
        buttonLabel: 'Link card'
      });
    }
    if (isNoOffer) {
      var _router$query;
      return __jsx(McoCardPromptContainer, null, __jsx(MobileDisplayOnly, null, __jsx(McoPromptMessage, {
        heading: bodyContent.checkBackMsg,
        subHeading: bodyContent.checkBackSubheading
      })), __jsx(CardButton, {
        primary: true,
        outline: true,
        pageLink: "/card-offers",
        "data-attribute": "view-all-my-offers",
        "data-brand": (_router$query = router.query) === null || _router$query === void 0 ? void 0 : _router$query.slug
      }, "View All My Offers"));
    }
    if (bodyContent.isOfferExpired) {
      var _router$query2;
      return __jsx(CardButton, {
        primary: true,
        outline: true,
        pageLink: "/card-offers",
        "data-attribute": "view-all-my-offers",
        "data-brand": (_router$query2 = router.query) === null || _router$query2 === void 0 ? void 0 : _router$query2.slug
      }, "View All My Offers");
    }
    return __jsx(React.Fragment, null, __jsx(CardButton, {
      primary: true,
      block: true,
      onPress: function onPress() {
        return handleCardButton(content.merchantSiteUrl);
      },
      "data-attribute": "shop-now",
      "data-brand": (_router$query3 = router.query) === null || _router$query3 === void 0 ? void 0 : _router$query3.slug
    }, "Shop Now"), content.hasStore && __jsx(CardButton, {
      primary: true,
      outline: true,
      onPress: function onPress() {
        return handleCardButton(content.findStoreUrl);
      },
      "data-attribute": "find-store",
      "data-brand": (_router$query4 = router.query) === null || _router$query4 === void 0 ? void 0 : _router$query4.slug
    }, "Find Store"));
  };
  return __jsx(McoBottomSideCardStyled, null, __jsx(MobileDisplayOnly, null, content.subHeading && __jsx(CardSubHeadingContainer, null, __jsx(CardSubHeading, {
    copy: content.subHeading
  }))), __jsx(McoButtonContainer, {
    hasStore: content.hasStore
  }, renderCardBody(content)), __jsx(McoTermsButtonContainer, null, content.termsLabel && __jsx(CardTermsButton, {
    primary: true,
    buttonLabel: content.termsLabel,
    onClick: handleCardFlip
  })));
};
export default withRouter(McoBottomSideCard);