import styled from 'styled-components';
import { fontSizes, colours } from "../../../../constants/css";
import { Container } from "../../../_global";
export var TermsContainer = styled(Container).withConfig({
  displayName: "style__TermsContainer",
  componentId: "sc-xmw21v-0"
})(["display:flex;flex-direction:column;align-items:flex-start;"]);
export var Copy = styled.p.withConfig({
  displayName: "style__Copy",
  componentId: "sc-xmw21v-1"
})(["font-size:", ";line-height:1.58;margin:0;a{color:", ";}"], fontSizes.xs, colours.qantasTermsGrey);
export var Heading = styled(Copy).withConfig({
  displayName: "style__Heading",
  componentId: "sc-xmw21v-2"
})(["font-weight:bold;margin-bottom:16px;"]);
export var Link = styled.a.withConfig({
  displayName: "style__Link",
  componentId: "sc-xmw21v-3"
})(["color:inherit;"]);