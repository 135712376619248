import styled from 'styled-components';
export var ErrorContainer = styled.div.withConfig({
  displayName: "style__ErrorContainer",
  componentId: "sc-535rnb-0"
})(["background:", ";width:100%;padding:16px;@media ", "{padding:16px;}"], function (_ref) {
  var theme = _ref.theme;
  return theme.colours.qantasWarningHover;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.responsiveQuery.desktop;
});
export var ErrorHeading = styled.p.withConfig({
  displayName: "style__ErrorHeading",
  componentId: "sc-535rnb-1"
})(["font-size:", ";font-family:", ";line-height:1.14;letter-spacing:1px;text-transform:uppercase;color:", ";text-align:left;margin:0;"], function (_ref3) {
  var theme = _ref3.theme;
  return theme.fontSizes.small;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.customFonts.ciutadellaMed;
}, function (_ref5) {
  var theme = _ref5.theme;
  return theme.colours.qantasCharcoal;
});