var __jsx = React.createElement;
import React from 'react';
import { RingSpinner } from "../../_global";
import { ActivatingText } from "./style";
export var Loading = function Loading(_ref) {
  var copy = _ref.copy;
  return __jsx(React.Fragment, null, __jsx(RingSpinner, null), copy && __jsx(ActivatingText, null, copy));
};
Loading.defaultProps = {
  copy: null
};