import _defineProperty from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import moment from 'moment';
import { OFFER_TYPES } from "../../../../../../constants/app";
import { isExpired, calculateDaysLeft } from "../../../../../Offer/offerUtils";
var masterCardVisa = "/static/offers/mastercard-visa@2x.png";
var masterCardVisaVertical = "/static/offers/mastercard-visa-vertical@2x.png";
var masterCardVisaAmexVertical = "/static/offers/mastercard-visa-amex-vertical@2x.png";
var masterCardVisaAmex = "/static/offers/mastercard-visa-amex@2x.png";
import { getEarnPoints } from "./getEarnPoints";
import { getRedemption } from "./getRedemption";
export var getInitialContent = function getInitialContent(_ref) {
  var isVisaAmexEnabled = _ref.isVisaAmexEnabled;
  var baseContent = {
    iconList: [{
      src: masterCardVisa,
      alt: 'Mastercard and Visa icons',
      type: 'stackedLogos',
      desktopSrc: masterCardVisaVertical
    }],
    hasStore: false,
    loginMsg: 'Log in to see if this offer is available for you',
    linkMsg: 'Link your Australian Mastercard® or Visa® card* to unlock exclusive bonus points offers',
    checkBackMsg: 'Check back soon',
    checkBackSubheading: 'New offers selected just for you are added weekly',
    debitCardDisclaimer: 'Got a Mastercard or Visa card?'
  };
  if (isVisaAmexEnabled) {
    baseContent.iconList = [{
      src: masterCardVisaAmex,
      alt: 'Card icons',
      type: 'stackedLogos',
      desktopSrc: masterCardVisaAmexVertical
    }];
    baseContent.linkMsg = 'Link your Australian Mastercard®, Visa® or American Express card* to unlock exclusive bonus points offers';
    baseContent.debitCardDisclaimer = 'Got a Mastercard, Visa or American Express card?';
  }
  return baseContent;
};
export var buildContentModel = function buildContentModel(user, flags) {
  return _objectSpread(_objectSpread({}, getInitialContent(flags)), {}, {
    isLogin: user.authenticated || user.authorized,
    isLinkedMcard: user.isLinkedMcard
  });
};
export var buildMcoContent = function buildMcoContent(mcoOfferData, user, flags) {
  /* Retrieve days left and offer expired */
  var isOfferExpired = isExpired(OFFER_TYPES.MCO, mcoOfferData.eventEndDate);
  var offerDaysLeft = calculateDaysLeft(mcoOfferData.eventEndDate);
  var earnPoints = getEarnPoints(mcoOfferData);
  var redemption = getRedemption(mcoOfferData.redemptionType, flags);
  var today = moment.tz('Australia/Sydney').format('DD-MM-YYYY');
  var expiredDate = moment(mcoOfferData.eventEndDate).format('DD-MM-YYYY');
  var isExpiredToday = today === expiredDate;
  var subHeading = mcoOfferData.discountType === 'ABSOLUTE' ? mcoOfferData.headline.split(' ').slice(4).join(' ') : mcoOfferData.headline.split(' ').slice(6).join(' '); // extract the heading, keep the rest for subheading
  return _objectSpread(_objectSpread(_objectSpread({}, earnPoints), redemption), {}, {
    termsLabel: 'Terms & Conditions',
    subHeading: subHeading,
    isLogin: user.authenticated || user.authorized,
    findStoreUrl: mcoOfferData.merchantStoreLocatorURL,
    merchantSiteUrl: mcoOfferData.merchantWebsiteURL,
    isLinkedMcard: true,
    isOfferExpired: isOfferExpired,
    expiredDate: moment(mcoOfferData.eventEndDate).format('Do MMM YYYY'),
    isExpiredToday: isExpiredToday,
    offerDaysLeft: offerDaysLeft,
    termsCopy: mcoOfferData.longDescription
  });
};