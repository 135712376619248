import styled from 'styled-components';
export var McoPromptHeading = styled.p.withConfig({
  displayName: "style__McoPromptHeading",
  componentId: "sc-10iyox3-0"
})(["font-size:", ";font-family:", ";text-align:left;margin-bottom:", ";color:", ";@media ", "{font-size:", ";margin-top:0;}"], function (_ref) {
  var theme = _ref.theme;
  return theme.fontSizes.base;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.customFonts.ciutadellaMed;
}, function (props) {
  return props.isSubHeading ? '0' : '15px';
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.colours.qantasCharcoal;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.responsiveQuery.desktop;
}, function (_ref5) {
  var theme = _ref5.theme;
  return theme.fontSizes.xxl;
});
export var McoPromptMessageContainer = styled.div.withConfig({
  displayName: "style__McoPromptMessageContainer",
  componentId: "sc-10iyox3-1"
})(["display:flex;flex-direction:column;margin-bottom:0;min-height:98px;"]);
export var McoPromptSubheadingContainer = styled.div.withConfig({
  displayName: "style__McoPromptSubheadingContainer",
  componentId: "sc-10iyox3-2"
})(["margin:8px 0 15px 0;"]);