var __jsx = React.createElement;
/** @format */

import React from 'react';
import Link from 'next/link';
var FlightIcon = "/static/merchant/flight-icon.svg";
import { BannerCopy, BannerTitle, JoinLink, RightArrow } from "../Style/index";
import { merchantProps } from "../Detail/merchantProps";
var BonusBanner = function BonusBanner(props) {
  var merchant = props.merchant;
  var buildLink = function buildLink(bonusB) {
    var link = bonusB.ctaLink || '';
    return link.replace(/direct-member-join/, "direct-member-join?mid=".concat(merchant.merchantId.replace(/\+/g, '%2B')));
  };
  var isExternalLink = function isExternalLink(link) {
    return link && link.match(/^http/);
  };
  var celebrusName = "merchant_special_".concat(merchant.merchantId);
  return __jsx(React.Fragment, null, merchant.bonusBanner && merchant.bonusBanner.map(function (bonusB) {
    return __jsx(BannerCopy, null, __jsx(Link, {
      href: buildLink(bonusB)
    }, __jsx("a", {
      target: isExternalLink(bonusB.ctaLink) ? '_blank' : ''
    }, __jsx(BannerTitle, null, __jsx("img", {
      src: FlightIcon,
      alt: "flight icon"
    }), bonusB.header), __jsx("p", null, bonusB.body), __jsx(JoinLink, {
      hasName: celebrusName
    }, __jsx("span", null, bonusB.ctaName || 'Join now'), __jsx(RightArrow, null)))));
  }));
};
export default BonusBanner;