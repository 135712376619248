import styled from 'styled-components';
import { Icon } from "../../../../../_global";
export var CloseButton = styled.button.withConfig({
  displayName: "style__CloseButton",
  componentId: "sc-ajxa7i-0"
})(["background:none;border:none;cursor:pointer;outline:transparent;padding:0;margin-left:37px;"]);
export var CardTermsStyled = styled.div.withConfig({
  displayName: "style__CardTermsStyled",
  componentId: "sc-ajxa7i-1"
})(["display:flex;flex-direction:column;background:", ";"], function (_ref) {
  var theme = _ref.theme;
  return theme.colours.qantasWhite;
});
export var TermsHeaderContainer = styled.div.withConfig({
  displayName: "style__TermsHeaderContainer",
  componentId: "sc-ajxa7i-2"
})(["display:flex;justify-content:flex-end;border-bottom:1px solid #ececec;width:100%;padding:14px 22px 14px 14px;justify-content:flex-end;align-items:center;"]);
export var TermInfoContainer = styled.div.withConfig({
  displayName: "style__TermInfoContainer",
  componentId: "sc-ajxa7i-3"
})(["padding:0 16px;max-height:14em;overflow-y:auto;@media ", "{padding:0 56px;max-height:12em;}"], function (_ref2) {
  var theme = _ref2.theme;
  return theme.responsiveQuery.desktop;
});
export var TermLabelContainer = styled.div.withConfig({
  displayName: "style__TermLabelContainer",
  componentId: "sc-ajxa7i-4"
})(["display:flex;margin:13px 0 5px 0;"]);
export var IconStyled = styled(Icon).withConfig({
  displayName: "style__IconStyled",
  componentId: "sc-ajxa7i-5"
})(["margin-right:5px;"]);
export var TermLabel = styled.p.withConfig({
  displayName: "style__TermLabel",
  componentId: "sc-ajxa7i-6"
})(["font-size:", ";", " font-weight:500;font-family:", ";margin:0;"], function (_ref3) {
  var theme = _ref3.theme;
  return theme.fontSizes.base;
}, function (props) {
  return props.headingColor && "color: ".concat(props.headingColor, ";");
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.customFonts.ciutadellaMed;
});
export var TermsCopy = styled.p.withConfig({
  displayName: "style__TermsCopy",
  componentId: "sc-ajxa7i-7"
})(["font-size:", ";margin-top:0;text-align:left;"], function (_ref5) {
  var theme = _ref5.theme;
  return theme.fontSizes.small;
});