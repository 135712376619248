var __jsx = React.createElement;
import React from 'react';
import _ from 'lodash';
import CardTerms from "../../BaseMerchantCard/CardTerms";
var OlmBackSideCard = function OlmBackSideCard(_ref) {
  var isExclusions = _ref.isExclusions,
    merchant = _ref.merchant,
    content = _ref.content,
    onCloseBackCard = _ref.onCloseBackCard;
  if (isExclusions) {
    return __jsx(CardTerms, {
      termsLabel: content.exclusions.label,
      termsCopy: merchant.exclusions,
      iconUrl: content.exclusions.iconLabel,
      colorLabel: content.exclusions.colorLabel,
      onClose: onCloseBackCard
    });
  }
  var isMerchantDetailEmpty = _.isNil(merchant.details) && _.isEmpty(merchant.details);
  return !isMerchantDetailEmpty && __jsx(CardTerms, {
    termsLabel: content.termsLabel,
    bonusTermCopy: merchant.promoted && merchant.promoted.prepend_special_terms,
    termsCopy: merchant.details[0].info_special_terms,
    onClose: onCloseBackCard
  });
};
OlmBackSideCard.defaultProps = {
  isExclusions: false
};
export default OlmBackSideCard;