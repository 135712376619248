import styled from 'styled-components';
import { Button } from "../../../../../_global";
import { colours } from "../../../../../../constants/css";
export var CardFooterContainer = styled.div.withConfig({
  displayName: "style__CardFooterContainer",
  componentId: "sc-11ap6c4-0"
})(["display:flex;flex-direction:column;align-items:flex-start;padding:0 15px;@media ", "{flex-direction:row-reverse;justify-content:space-between;align-items:center;position:fixed;top:68%;width:88%;right:5%;padding:0;}"], function (_ref) {
  var theme = _ref.theme;
  return theme.responsiveQuery.desktop;
});
export var CardButton = styled(Button).withConfig({
  displayName: "style__CardButton",
  componentId: "sc-11ap6c4-1"
})(["display:block;width:100%;margin:35px 0 10px 0;@media ", "{display:flex;align-self:center;justify-content:center;max-width:240px;height:44px;margin:0;}", " ", ""], function (_ref2) {
  var theme = _ref2.theme;
  return theme.responsiveQuery.desktop;
}, function (props) {
  return !props.primary && "\n    color: ".concat(colours.qantasRedEarth, ";\n    background-color: ").concat(colours.qantasWhite, ";\n    border-color: ").concat(colours.qantasRedEarth, ";\n    &:hover, &:active {\n      background-color: ").concat(colours.qantasRed, ";\n      color: ").concat(colours.qantasWhite, "\n    }\n  ");
}, function (props) {
  return props.disabled && "\n    color: ".concat(colours.qantasCharcoal, ";\n    background-color: ").concat(colours.qantasLightGrey, ";\n    border-color: ").concat(colours.qantasLightGrey, ";\n    &:hover, &:active {\n      background-color: ").concat(colours.qantasLightGrey, ";\n      color: ").concat(colours.qantasCharcoal, "\n    }\n  ");
});
export var CardTermsButtonContainer = styled.div.withConfig({
  displayName: "style__CardTermsButtonContainer",
  componentId: "sc-11ap6c4-2"
})(["display:flex;flex-direction:", ";width:100%;justify-content:space-between;@media ", "{flex-direction:column;align-items:flex-start;}"], function (props) {
  return props.isExclusions ? 'row-reverse' : 'row';
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.responsiveQuery.desktop;
});