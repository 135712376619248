var __jsx = React.createElement;
import React from 'react';
import CardTerms from "../../BaseMerchantCard/CardTerms";
var McoBackSideCard = function McoBackSideCard(_ref) {
  var content = _ref.content,
    onCloseBackCard = _ref.onCloseBackCard;
  return __jsx(CardTerms, {
    termsLabel: content.termsLabel,
    termsCopy: content.termsCopy,
    onClose: onCloseBackCard
  });
};
export default McoBackSideCard;