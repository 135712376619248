import styled from 'styled-components';
import { layoutSizes, colours, fontSizes } from "../../../../constants/css";
export var MethodIconsContainer = styled.div.withConfig({
  displayName: "style__MethodIconsContainer",
  componentId: "sc-1sr9m3u-0"
})(["display:flex;justify-content:center;align-items:center;@media only screen and (min-width:", "){justify-content:left;}"], layoutSizes.desktopWidth);
export var MethodLabel = styled.p.withConfig({
  displayName: "style__MethodLabel",
  componentId: "sc-1sr9m3u-1"
})(["font-size:", ";line-height:1;text-transform:uppercase;color:", ";margin-top:19px;margin-bottom:5px;@media only screen and (min-width:", "){font-size:", ";line-height:0.81;margin-top:50px;}"], fontSizes.small, colours.qantasCharcoal, layoutSizes.desktopWidth, fontSizes.base);
export var MethodImg = styled.img.withConfig({
  displayName: "style__MethodImg",
  componentId: "sc-1sr9m3u-2"
})(["width:37px;height:37px;margin-right:5px;&:last-child{width:60px;height:34px;}@media only screen and (min-width:", "){width:47px;height:47px;&:last-child{width:auto;height:40px;}}"], layoutSizes.desktopWidth);
export var PlusIcon = styled.img.withConfig({
  displayName: "style__PlusIcon",
  componentId: "sc-1sr9m3u-3"
})(["width:8px;height:8px;margin:0 4px;"]);