/** @format */

import { ERROR } from "../../reducers/partnerLinking";
import { useEffect } from 'react';
var stateToPropsWoolworth = function stateToPropsWoolworth(_ref) {
  var _ref$user = _ref.user,
    authorized = _ref$user.authorized,
    authenticated = _ref$user.authenticated,
    statusCode = _ref.partner.statusCode;
  return {
    authorized: authorized,
    authenticated: authenticated,
    statusCode: authenticated || authorized ? statusCode : ERROR
  };
};
var useMount = function useMount(authenticated, authorized, merchantId, getStatus) {
  useEffect(function () {
    if (authenticated || authorized) {
      getStatus(null, merchantId);
    }
  }, [authenticated, authorized, merchantId]);
};
export { stateToPropsWoolworth, useMount };