var __jsx = React.createElement;
import React from 'react';
import { colours } from "../../../../../../constants/css";
import { CardTermsButtonLabelContainer, CardTermsButtonLabel, CardTermsButtonLabelIcon } from "./style";
var CardTermsButton = function CardTermsButton(_ref) {
  var buttonLabel = _ref.buttonLabel,
    onClick = _ref.onClick,
    textColor = _ref.textColor,
    icon = _ref.icon;
  return __jsx(CardTermsButtonLabelContainer, null, icon && __jsx(CardTermsButtonLabelIcon, {
    src: icon,
    alt: "".concat(buttonLabel, "-icon")
  }), __jsx(CardTermsButtonLabel, {
    color: textColor,
    onPress: onClick
  }, buttonLabel));
};
CardTermsButton.defaultProps = {
  textColor: colours.qantasGrey40,
  icon: null
};
export default CardTermsButton;