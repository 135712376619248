import styled from 'styled-components';
export var H2Styled = styled.h2.withConfig({
  displayName: "style__H2Styled",
  componentId: "sc-y2180y-0"
})(["font-size:", " !important;font-weight:500;font-family:", ";width:100%;display:flex;flex-wrap:wrap;align-items:center;margin:0;line-height:1.09;color:", ";text-align:left;@media ", "{font-size:", " !important;width:auto;line-height:1.25;}"], function (_ref) {
  var theme = _ref.theme;
  return theme.headingSizes.h3;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.customFonts.ciutadellaMed;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.colours.qantasCharcoal;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.responsiveQuery.desktop;
}, function (_ref5) {
  var theme = _ref5.theme;
  return theme.fontSizes.xxl;
});
export var PointsStyled = styled.span.withConfig({
  displayName: "style__PointsStyled",
  componentId: "sc-y2180y-1"
})(["color:", ";font-size:2.25rem;font-family:", ";font-weight:normal;line-height:0.67;@media ", "{font-size:2.5rem;line-height:1.25;}"], function (_ref6) {
  var theme = _ref6.theme;
  return theme.colours.qantasRed;
}, function (_ref7) {
  var theme = _ref7.theme;
  return theme.customFonts.ciutadellaReg;
}, function (_ref8) {
  var theme = _ref8.theme;
  return theme.responsiveQuery.desktop;
});
export var WasPointsStyled = styled.span.withConfig({
  displayName: "style__WasPointsStyled",
  componentId: "sc-y2180y-2"
})(["text-decoration:line-through;font-family:", ";font-size:", ";line-height:1.6;color:", ";@media ", "{font-size:", ";}"], function (_ref9) {
  var theme = _ref9.theme;
  return theme.customFonts.ciutadellaReg;
}, function (_ref10) {
  var theme = _ref10.theme;
  return theme.fontSizes.base;
}, function (_ref11) {
  var theme = _ref11.theme;
  return theme.colours.qantasCharcoal;
}, function (_ref12) {
  var theme = _ref12.theme;
  return theme.responsiveQuery.desktop;
}, function (_ref13) {
  var theme = _ref13.theme;
  return theme.fontSizes.xl;
});
export var CardHeadingcontainer = styled.div.withConfig({
  displayName: "style__CardHeadingcontainer",
  componentId: "sc-y2180y-3"
})(["width:100%;display:flex;align-items:flex-start;flex-wrap:wrap;height:95px;@media ", "{align-items:center;flex-wrap:nowrap;height:55px;}"], function (_ref14) {
  var theme = _ref14.theme;
  return theme.responsiveQuery.desktop;
});