var __jsx = React.createElement;
import React from 'react';
var pointingArrow = "/static/merchant/pointing-arrow.svg";
import CardHeading from "../../BaseMerchantCard/CardHeading";
import CardSubHeading from "../../BaseMerchantCard/CardSubHeading";
import OlmErrorMessage from "../OlmErrorMessage";
import { DesktopDisplayOnly } from "../../BaseMerchantCard/style";
import { CardSubHeadingContainer } from "../style";
import { OlmRightSideContainerStyled, PointingArrow } from "./style";
var OlmRightSideCard = function OlmRightSideCard(_ref) {
  var merchant = _ref.merchant,
    content = _ref.content,
    isFetchingError = _ref.isFetchingError;
  return __jsx(OlmRightSideContainerStyled, {
    "data-nosnippet": true
  }, __jsx(CardHeading, {
    actualPoints: merchant.rebate.rebate_user,
    prevPoints: merchant.was_rebate.rebate_user,
    perValue: 1
  }), !isFetchingError ? __jsx(React.Fragment, null, __jsx(DesktopDisplayOnly, null, __jsx(CardSubHeadingContainer, null, __jsx(CardSubHeading, {
    copy: content.subHeading
  }))), __jsx(PointingArrow, {
    src: pointingArrow,
    alt: "pointing-arrow"
  })) : __jsx(DesktopDisplayOnly, null, __jsx(OlmErrorMessage, {
    merchantName: merchant.merchantName
  })));
};
OlmRightSideCard.defaultProps = {
  isFetchingError: false
};
export default OlmRightSideCard;