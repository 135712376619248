import _extends from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/extends";
var __jsx = React.createElement;
import React from 'react';
import ReactCardFlip from 'react-card-flip';
import { BaseMerchantCardWrapper, BaseMerchantCardContainer, SplitCardContainer, LeftContainer, RightContainer, CardFooterCaptionContainer } from "./style";

/* This component allows to customise the card
into top row, multiple cols (using left and right containers) or build
more columns within those container to create more than 2 cols
or just a full-width card(using top row or children) */

var BaseMerchantCard = function BaseMerchantCard(_ref) {
  var leftSide = _ref.leftSide,
    rightSide = _ref.rightSide,
    children = _ref.children,
    topSide = _ref.topSide,
    backSideCard = _ref.backSideCard,
    footerCardCaption = _ref.footerCardCaption,
    isCardFlipped = _ref.isCardFlipped,
    isPebbleVisible = _ref.isPebbleVisible,
    isFetchingError = _ref.isFetchingError;
  var reactFlipProps = {
    isFlipped: isCardFlipped
  };
  return __jsx(BaseMerchantCardWrapper, {
    className: "base-merchant-card-wrapper",
    isCardFlipped: isCardFlipped,
    isPebbleVisible: isPebbleVisible,
    "data-nosnippet": true
  }, __jsx(ReactCardFlip, _extends({}, reactFlipProps, {
    flipDirection: "vertical"
  }), __jsx(BaseMerchantCardContainer, {
    key: "front"
  }, topSide || null, leftSide && rightSide && __jsx(SplitCardContainer, {
    isFetchingError: isFetchingError
  }, __jsx(LeftContainer, {
    isFetchingError: isFetchingError
  }, leftSide), __jsx(RightContainer, {
    isFetchingError: isFetchingError
  }, rightSide)), children), __jsx(React.Fragment, {
    key: "back"
  }, backSideCard)), footerCardCaption && __jsx(CardFooterCaptionContainer, null, footerCardCaption));
};
BaseMerchantCard.defaultProps = {
  children: null,
  leftSide: null,
  rightSide: null,
  topSide: null,
  backSideCard: null,
  footerCardCaption: null,
  isCardFlipped: false
};
export default BaseMerchantCard;