import styled from 'styled-components';
export var McoMerchantCardWrapper = styled.div.withConfig({
  displayName: "style__McoMerchantCardWrapper",
  componentId: "sc-141ceo5-0"
})(["display:flex;flex-direction:column;align-items:center;"]);
export var CardSubHeadingContainer = styled.div.withConfig({
  displayName: "style__CardSubHeadingContainer",
  componentId: "sc-141ceo5-1"
})(["display:flex;max-width:236px;height:56px;margin-top:6px;@media ", "{max-width:358px;height:auto;margin-top:0;}"], function (_ref) {
  var theme = _ref.theme;
  return theme.responsiveQuery.desktop;
});