var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
var Copy = styled.p.withConfig({
  displayName: "McoDebitCardDisclaimer__Copy",
  componentId: "sc-1tx1dxi-0"
})(["color:", ";@media ", "{margin-top:15px;}"], function (_ref) {
  var theme = _ref.theme;
  return theme.colours.qantasCharcoal;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.responsiveQuery.desktop;
});
var McoDebitCardDisclaimer = function McoDebitCardDisclaimer(_ref3) {
  var cardContent = _ref3.cardContent;
  return __jsx(Copy, null, "*", "".concat(cardContent, " "), "Find out how you can earn Qantas Points", " ", __jsx(Link, {
    href: "/faqs",
    as: "/faqs?id=25"
  }, __jsx("a", null, "here")), ".");
};
export default McoDebitCardDisclaimer;