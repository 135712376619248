var __jsx = React.createElement;
/** @format */

import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { Button } from "../../_global";
import { buttonTypeLabel } from "../Enum/ButtonEnum";
var CustomisedButton = function CustomisedButton(props) {
  var type = props.type;
  var renderScrollToTop = function renderScrollToTop() {
    return __jsx(ScrollToTop, {
      showUnder: 160,
      style: {
        zIndex: 99,
        textAlign: 'right',
        position: 'relative',
        bottom: 0
      }
    }, __jsx(Button, {
      outline: true,
      rounded: true
    }, buttonTypeLabel.scrollTotop.label));
  };
  return __jsx("div", null, type === buttonTypeLabel.scrollTotop.type && renderScrollToTop());
};
CustomisedButton.defaultProps = {
  type: null
};
export default CustomisedButton;