var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
var Copy = styled.p.withConfig({
  displayName: "CardFooterCaption__Copy",
  componentId: "sc-5q9b6o-0"
})(["color:", ";@media ", "{margin-top:15px;}"], function (_ref) {
  var theme = _ref.theme;
  return theme.colours.qantasCharcoal;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.responsiveQuery.desktop;
});
var CardFooterCaption = function CardFooterCaption(_ref3) {
  var days = _ref3.days;
  // eslint-disable-next-line no-restricted-globals
  if (days && days !== null && isNaN(days)) {
    return __jsx(Copy, null, days);
  }
  return __jsx(Copy, null, "Points usually credited to your account within\xA0", __jsx("strong", null, days, " days"));
};
export default CardFooterCaption;