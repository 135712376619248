import { layoutSizes, colours, customFonts, fontSizes } from "../../../constants/css";
import styled from 'styled-components';
import { Button } from "../../_global";
export var OfferDetails = styled.div.withConfig({
  displayName: "style__OfferDetails",
  componentId: "sc-s1evv5-0"
})(["p{margin-top:0;@media only screen and (min-width:", "){font-size:", ";text-align:left;}}"], layoutSizes.desktopWidth, fontSizes.large);
export var OfferTitle = styled.p.withConfig({
  displayName: "style__OfferTitle",
  componentId: "sc-s1evv5-1"
})(["font-size:", ";font-family:", ";span{font-size:40px;color:", ";}"], fontSizes.xl, customFonts.ciutadellaMed, colours.qantasRed);
export var CTAWrapper = styled.div.withConfig({
  displayName: "style__CTAWrapper",
  componentId: "sc-s1evv5-2"
})(["display:flex;justify-content:flex-end;button:first-child{margin-right:1rem;}"]);
export var JoinCta = styled(Button).attrs({
  name: function name(props) {
    return props.hasName || null;
  }
}).withConfig({
  displayName: "style__JoinCta",
  componentId: "sc-s1evv5-3"
})([""]);
export var FindCta = styled(Button).attrs({
  name: function name(props) {
    return props.hasName || null;
  }
}).withConfig({
  displayName: "style__FindCta",
  componentId: "sc-s1evv5-4"
})(["max-width:15rem;margin:0.625rem auto;"]);
export var LoadingWrapper = styled.div.withConfig({
  displayName: "style__LoadingWrapper",
  componentId: "sc-s1evv5-5"
})(["display:flex;align-items:center;span{letter-spacing:1px;font-family:", ";margin-right:1rem;}span:first-child,svg{margin-right:1rem;}"], customFonts.ciutadellaMed);