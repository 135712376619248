var __jsx = React.createElement;
import React from 'react';
var excludeIcon = "/static/icons/ico_exclude.svg";
import CardTerms from "../../BaseMerchantCard/CardTerms";
var DirectBackSideCard = function DirectBackSideCard(_ref) {
  var isExclusions = _ref.isExclusions,
    tile = _ref.tile,
    onCloseBackCard = _ref.onCloseBackCard;
  if (isExclusions) {
    return __jsx(CardTerms, {
      termsLabel: "Exclusions",
      termsCopy: tile.exclusions,
      iconUrl: excludeIcon,
      colorLabel: "#f4750c",
      onClose: onCloseBackCard
    });
  }
  if (!tile || tile.terms === null) {
    return null;
  }
  return __jsx(CardTerms, {
    termsLabel: "Terms & Conditions",
    termsCopy: tile.terms,
    onClose: onCloseBackCard
  });
};
DirectBackSideCard.defaultProps = {
  isExclusions: false
};
export default DirectBackSideCard;