var __jsx = React.createElement;
import React from 'react';
import Markdown from 'react-markdown';
import { Button } from "../../../_global";
var EveryDayLogo = "/static/logos/everyday-rewards-small-card-2.svg";
import { HeaderContainer, PStyled, ChildrenContainer } from "../style";
import { HowToContainer, Heading, Icon, WWPartnerLogos, HowToButtonStyled, JoinWw, Arrow } from "./style";
var HowToLink = function HowToLink(_ref) {
  var content = _ref.content,
    children = _ref.children,
    clickAction = _ref.clickAction,
    clickAction2 = _ref.clickAction2;
  var _ref2 = content || {},
    title = _ref2.title,
    subtitle = _ref2.subtitle,
    description = _ref2.description,
    iconUrl = _ref2.iconUrl,
    iconAlt = _ref2.iconAlt,
    buttonLabel = _ref2.buttonLabel,
    noEveryDayLogo = _ref2.noEveryDayLogo;
  var showChildren = children && children.props && children.props.children && Array.isArray(children.props.children);
  return __jsx(HowToContainer, null, __jsx(HeaderContainer, null, title && __jsx(Heading, null, title)), subtitle && __jsx(PStyled, {
    align: "center",
    mobileAlign: "center"
  }, __jsx(Markdown, null, subtitle)), showChildren && __jsx(ChildrenContainer, null, children), description && __jsx(PStyled, {
    align: "center",
    mobileAlign: "center"
  }, __jsx(Markdown, null, description)), iconUrl && __jsx(Icon, {
    src: iconUrl,
    alt: iconAlt
  }), noEveryDayLogo ? null : __jsx(WWPartnerLogos, {
    src: EveryDayLogo,
    alt: "ww-partner-logos"
  }), noEveryDayLogo && buttonLabel && __jsx(HowToButtonStyled, {
    primary: true,
    onPress: function onPress() {
      return clickAction();
    }
  }, buttonLabel), noEveryDayLogo && clickAction2 && __jsx(JoinWw, null, "Not an Everyday Rewards member?", __jsx(Button, {
    link: true,
    onPress: function onPress() {
      return clickAction2();
    }
  }, "Join now ", __jsx(Arrow, null))));
};
HowToLink.defaultProps = {
  clickAction2: null
};
export default HowToLink;