import _extends from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/extends";
import _slicedToArray from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import _objectWithoutProperties from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
import _defineProperty from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
var _excluded = ["redirectUrl"];
var _howToContent;
var __jsx = React.createElement;
/** @format */

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Router from 'next/router';
import { graphql, compose } from 'react-apollo';
import _ from 'lodash';
import { useDecision } from '@optimizely/react-sdk';
import { fetchMemberStatus, ERROR, SUCCESS, NOT_FOUND, ALREADY_LINKED, LOADING } from "../../../reducers/partnerLinking";
import { getShoSessionTokens } from "../../../utils/sessionHelper";
import EventTrackingHelper from "../../../utils/trackingUtilities";
var wwPointsToQantas = "/static/merchant/ww-points-to-qantas-new.svg";
var linkIcon = "/static/directContentForm/link.svg";
import { Loading } from "../../Merchant/Linking/Loading";
import { Layout, GeoNZIPModalContainer } from "../../_global";
import HowItWorksBanner from "../../HowItWorksBanner";
import { openLoginModal } from "../../../reducers/user";
import { howItWorksTileContent } from "../Misc/copyObjects";
import isExternalUrl from "../../../utils/isExternalUrl";
var tickIcon = "/static/merchant/tick-icon.svg";
var starRewardIcon = "/static/icons/icon-star-reward.png";
var wwLogo = "/static/logos/woolworths_logo.svg";
var bigwLogo = "/static/logos/bigw_logo.svg";
var bwsLogo = "/static/logos/bws_logo.svg";
import Hero from "../../Campaign/Hero";
import { FAQWrapper } from "../../Merchant/Style";
import FAQs from "../../Faq/FAQs";
import query from "../../Merchant/Detail/query";
import isBrowser from "../../../utils/isBrowser";
import { getAuthStatus } from "../../../utils/getAuthStatus";
import { OPTIMIZELY_FLAGS, FEATURE_FLAG_DEFAULTS } from "../../../lib/optimizelyClient";
import HowToLink from "./HowToLink/index";
import ChooseQantas from "./ChooseQantas/index";
import WhereToEarn from "./WhereToEarn";
import Terms from "./Terms";
import EarnPoints from "./EarnPoints/index";
import { H1Styled, ContainerStyled, HeaderContainer, HowToSection, WhereToEarnSection, TermSection, RewardsLoadingSection, HeadingImgContainer, HeadingImg, MerchantLogoContainer, MerchantLogos, WWMerchant, StatusIconContainer, StatusIcon, ButtonStyled } from "./style";
import { stateToPropsWoolworth, useMount } from "../utilsWoolworths";
import { Tooltip } from "../Misc/tooltip";
import edrCampaignBannerQuery from "../edrCampaignBannerQuery";
var howToContent = (_howToContent = {}, _defineProperty(_howToContent, NOT_FOUND, [{
  title: 'How can I choose to earn Qantas Points?',
  description: 'Everyday Rewards offer a number of reward options. To choose to turn your Woolworths ' + 'Points into Qantas Points, just add your Frequent Flyer details into your Woolworths' + ' Rewards account.',
  iconUrl: linkIcon,
  iconAlt: 'Link Icon',
  buttonLabel: 'Link Account',
  buttonLink: '',
  linkURL: '/partner-host?id=null&ct=link',
  joinURL: '/partner-host?id=null&ct=join'
}, {
  title: 'Shop and scan your way towards a dream holiday',
  description: 'Link your Everyday Rewards and Qantas Frequent Flyer accounts to start collecting points.',
  iconUrl: linkIcon,
  iconAlt: 'Link Icon',
  buttonLabel: 'Link Account',
  buttonLink: '',
  linkURL: '/partner-host?id=null&ct=link',
  joinURL: '/partner-host?id=null&ct=join',
  noEveryDayLogo: true
}]), _defineProperty(_howToContent, ERROR, [{
  title: 'How can I earn Qantas Points with Everyday Rewards?',
  description: "Join Everyday Rewards, make your rewards choice 'Qantas Points' and every time your " + "Everyday Rewards points balance reaches 2,000, you'll see 1,000 Qantas Points land in " + 'your Frequent Flyer account.*',
  iconAlt: 'Switch Icon',
  buttonLabel: 'Choose Qantas Points',
  buttonLink: '',
  linkURL: '/link',
  joinURL: '/partner-host?id=null&ct=join'
}, {
  title: 'Shop and scan your way towards a dream holiday',
  description: '',
  iconUrl: starRewardIcon,
  iconAlt: 'Switch Icon',
  buttonLabel: 'Choose Qantas Points',
  buttonLink: '',
  linkURL: '/link',
  joinURL: '/partner-host?id=null&ct=join',
  noEveryDayLogo: true
}]), _defineProperty(_howToContent, SUCCESS, [{
  title: 'How can I earn Qantas Points with Everyday Rewards?',
  description: "Make your rewards choice 'Qantas Points' and every time your Everyday Rewards points " + "balance reaches 2,000, you'll see 1,000 Qantas Points land in your Frequent Flyer account.*",
  iconAlt: 'Switch Icon',
  buttonLabel: 'Choose Qantas Points',
  buttonLink: '',
  linkURL: '/link'
}, {
  title: 'Shop and scan your way towards a dream holiday',
  description: '',
  iconUrl: starRewardIcon,
  iconAlt: 'Switch Icon',
  buttonLabel: 'Choose Qantas Points',
  buttonLink: '',
  linkURL: '/link',
  noEveryDayLogo: true
}]), _defineProperty(_howToContent, ALREADY_LINKED, [{
  title: "You're earning Qantas Points with Everyday Rewards.",
  subtitle: 'Remember to scan your Everyday Rewards Card or add your card to your online account before checking out.',
  description: '',
  iconAlt: 'Already earning',
  noEveryDayLogo: true
}, {
  title: 'Shop and scan your way towards a dream holiday.',
  description: '',
  iconAlt: 'Already earning',
  noEveryDayLogo: true
}]), _howToContent);
var HEADER_CONTENT = 0;
var FOOTER_CONTENT = 1;
var RewardsLanding = function RewardsLanding(_ref) {
  var merchantId = _ref.merchantId,
    getStatus = _ref.getStatus,
    authenticated = _ref.authenticated,
    authorized = _ref.authorized,
    slug = _ref.slug,
    openLoginModal = _ref.openLoginModal,
    merchant = _ref.merchant,
    statusCode = _ref.statusCode,
    edrCampaignBanner = _ref.edrCampaignBanner,
    page = _ref.page,
    ipAddress = _ref.ipAddress;
  var redirectUrl = edrCampaignBanner.redirectUrl,
    banner = _objectWithoutProperties(edrCampaignBanner, _excluded);
  useMount(authenticated, authorized, merchantId, getStatus);
  var isLoading = statusCode === LOADING;
  var _useDecision = useDecision(OPTIMIZELY_FLAGS.WOOLWORTHS_MAINTENANCE),
    _useDecision2 = _slicedToArray(_useDecision, 1),
    isInMaintenanceMode = _useDecision2[0].enabled;
  var _useDecision3 = useDecision(OPTIMIZELY_FLAGS.WOOLWORTHS_MAINTENANCE_LINK),
    _useDecision4 = _slicedToArray(_useDecision3, 1),
    _useDecision4$0$varia = _useDecision4[0].variables.value,
    ctaLinkForMaintenanceMode = _useDecision4$0$varia === void 0 ? FEATURE_FLAG_DEFAULTS[OPTIMIZELY_FLAGS.WOOLWORTHS_MAINTENANCE_LINK] : _useDecision4$0$varia;
  useEffect(function () {
    window.scrollTo(0, 0);
  }, []);
  useEffect(function () {
    var auth;
    if (isBrowser) {
      var _getShoSessionTokens = getShoSessionTokens(),
        token = _getShoSessionTokens.accessToken;
      if (authorized) {
        auth = token ? 'Authenticated' : 'Remembered';
      } else {
        auth = 'Not authenticated';
      }
      EventTrackingHelper.trackGenericAction(auth);
    }
  }, [authorized, isBrowser]);
  var renderHowToContent = function renderHowToContent(index) {
    var content = howToContent[statusCode];
    if (!content) {
      content = howToContent[ERROR];
    }
    content = Array.isArray(content) ? content[index] : content;
    var onClick = function onClick(loggedIn, link) {
      if (isInMaintenanceMode) {
        window.open(ctaLinkForMaintenanceMode, '_blank');
        return;
      }
      if (isExternalUrl(link)) {
        window.open(link);
      } else {
        loggedIn ? Router.push(link) : openLoginModal(link);
      }
    };
    return __jsx(HowToSection, null, index === HEADER_CONTENT && __jsx(ChooseQantas, {
      content: content,
      clickAction: function clickAction() {
        onClick(authorized, content.linkURL || '/');
        // scenario 1.2 choose cta
        EventTrackingHelper.trackGenericAction('EDR CTA choose QF', {
          cta_name: 'EDR CTA choose QF',
          authentication: getAuthStatus(authenticated, authorized)
        });
      },
      clickAction2: content.joinURL ? function () {
        onClick(authenticated, content.joinURL);
        // scenario 1.1.2.2 choose cta?
        // scenario 1.3
        EventTrackingHelper.trackGenericAction('EDR CTA join', {
          cta_name: 'EDR CTA join'
        });
      } : null
    }), statusCode === ALREADY_LINKED ? __jsx(StatusIconContainer, null, __jsx(StatusIcon, {
      src: tickIcon
    })) : null, __jsx(HowToLink, {
      content: content,
      clickAction: function clickAction() {
        onClick(authorized, content.linkURL || '/');
      },
      clickAction2: content.joinURL ? function () {
        onClick(authenticated, content.joinURL);
      } : null
    }, statusCode === ALREADY_LINKED ? __jsx(MerchantLogoContainer, null, __jsx(WWMerchant, null, __jsx(MerchantLogos, {
      src: wwLogo,
      alt: "Woolworths logo"
    }), __jsx(ButtonStyled, {
      primary: true,
      onPress: function onPress() {
        return onClick(true, 'https://www.woolworths.com.au/');
      }
    }, "SHOP NOW")), __jsx(WWMerchant, null, __jsx(MerchantLogos, {
      src: bwsLogo,
      alt: "BWS logo"
    }), __jsx(ButtonStyled, {
      primary: true,
      onPress: function onPress() {
        return onClick(true, 'https://bws.com.au/');
      }
    }, "SHOP NOW")), __jsx(WWMerchant, null, __jsx(MerchantLogos, {
      src: bigwLogo,
      alt: "BIG W logo"
    }), __jsx(ButtonStyled, {
      primary: true,
      onPress: function onPress() {
        return onClick(true, 'https://www.bigw.com.au/');
      }
    }, "SHOP NOW"))) : __jsx(RewardsLoadingSection, {
      show: isLoading
    }, __jsx(Loading, null))));
  };
  var metaTitle = _.get(merchant, 'metaTags', 'Everyday Rewards');
  var metaDescription = _.get(merchant, 'metaDescription', 'Everyday Rewards');
  return __jsx(Layout, {
    slug: slug,
    title: metaTitle,
    description: metaDescription
  }, banner.title && __jsx("a", {
    href: redirectUrl
  }, __jsx(Hero, _extends({}, banner, {
    showTitle: false
  }))), __jsx(HeadingImgContainer, {
    hasBannerAbove: !!banner.title
  }, __jsx(HeadingImg, {
    src: wwPointsToQantas,
    alt: "points Conversion"
  })), statusCode === ALREADY_LINKED ? null : __jsx(ContainerStyled, null, __jsx(HeaderContainer, null, __jsx(H1Styled, null, "Earn Qantas Points with Everyday Rewards")), __jsx(EarnPoints, null)), renderHowToContent(HEADER_CONTENT), statusCode !== ALREADY_LINKED && howItWorksTileContent.length > 0 && __jsx(HowItWorksBanner, {
    banners: howItWorksTileContent,
    isContrastIcon: true
  }), __jsx(Tooltip, null), __jsx(WhereToEarnSection, null, __jsx(WhereToEarn, null)), __jsx(RewardsLoadingSection, {
    show: isLoading
  }, __jsx(Loading, null)), statusCode === ALREADY_LINKED ? null : renderHowToContent(FOOTER_CONTENT), merchant && merchant.merchantFaq && merchant.merchantFaq.length > 0 && __jsx(FAQWrapper, {
    wideView: true
  }, __jsx("h2", null, "Frequently asked questions"), __jsx(FAQs, {
    faqSections: merchant.merchantFaq
  })), __jsx(TermSection, null, __jsx(Terms, {
    page: page
  })), __jsx(GeoNZIPModalContainer, {
    ipAddress: ipAddress
  }));
};
var mapStateToProps = stateToPropsWoolworth;
var mapDispatchToProps = {
  getStatus: fetchMemberStatus,
  openLoginModal: openLoginModal
};
RewardsLanding.defaultProps = {
  authorized: false,
  authenticated: false,
  merchantId: 'woolworths',
  edrCampaignBanner: {}
};
export default compose(connect(mapStateToProps, mapDispatchToProps), graphql(query.merchantMetaInfo, {
  options: function options(ownProps) {
    return {
      variables: {
        merchantId: ownProps.slug
      }
    };
  },
  props: function props(_ref2) {
    var data = _ref2.data;
    return data;
  }
}), graphql(edrCampaignBannerQuery, {
  props: function props(_ref3) {
    var data = _ref3.data;
    return {
      edrCampaignBanner: data.edrCampaignBanner
    };
  }
}))(RewardsLanding);