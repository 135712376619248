import _defineProperty from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
var _icons;
import { REDEMPTION_TYPES } from "../../../../../constants/app";
var onlineIcon = "/static/merchant/online.png";
var instoreIcon = "/static/merchant/instore.png";
var icons = (_icons = {}, _defineProperty(_icons, REDEMPTION_TYPES.ONLINE, {
  iconLabel: 'ONLINE',
  iconList: [{
    src: onlineIcon,
    alt: 'Online'
  }],
  hasStore: false
}), _defineProperty(_icons, REDEMPTION_TYPES.INSTORE, {
  iconLabel: 'IN-STORE',
  iconList: [{
    src: instoreIcon,
    alt: 'In store'
  }],
  hasStore: true
}), _defineProperty(_icons, REDEMPTION_TYPES.BOTH, {
  iconLabel: 'ONLINE & IN-STORE',
  iconList: [{
    src: onlineIcon,
    alt: 'Online'
  }, {
    src: instoreIcon,
    alt: 'In store'
  }],
  hasStore: true
}), _icons);
export var getRedemption = function getRedemption(redemptionType) {
  return icons[redemptionType] || {
    iconLabel: null,
    iconList: [],
    hasStore: false
  };
};